<template>
  <modal
    :show="showModal"
    modal-content-classes="bg-dark-200"
    :centered="true"
    :show-close="true"
    body-classes="p-0"
    class="transcript-export--modal"
    @close="showModal = false"
  >
    <div
      slot="header"
      class="d-flex align-items-center"
    >
      <h6 class="text-white mb-0 font-weight-bold">
        Transcript Export
      </h6>
    </div>
    <div class="h-divider mt-6" />
    <div class="p-5">
      <div class="mb-2 text-lightblue">
        Choose exporting format
      </div>
      <div
        v-for="(option, i) in options"
        :key="i"
        class="d-flex align-items-center py-3 rounded-label px-3 mb-3 cursor-pointer"
        :class="
          type != option.value ? 'bg-dark border' : 'transcript-export--active'
        "
        @click="type = option.value"
      >
        <base-radio
          v-model="type"
          class="mt-0 mr-0"
          radio-name="exportType"
          inline
          :name="option.value"
        />
        <component
          :is="option.icon"
          color="grey"
        />
        <div class="text-white ml-2 flex-grow-1">
          {{ option.name }}
        </div>
        <small class="text-lightblue">{{ option.extension }}</small>
      </div>
      <base-checkbox
        v-model="withAudio"
        inline
        class="text-white ml-3"
        :disabled="withAudioDisabled"
      >
        Export with audio file
      </base-checkbox>
    </div>
    <div class="h-divider" />
    <div class="p-3 d-flex">
      <div class="flex-1" />
      <base-button
        simple
        @click="() => showModal=false"
      >
        Cancel
      </base-button>
      <base-button @click="emit">
        Export
      </base-button>
    </div>
  </modal>
</template>

<script>
import {FileTextIcon, MusicIcon} from "vue-feather-icons";
import {ExportType} from "../../../util/exportUtil";
import BaseButton from "../../BaseButton.vue";
import BaseCheckbox from "../../Inputs/BaseCheckbox.vue";
import BaseRadio from "../../Inputs/BaseRadio.vue";
import Modal from "../Modal.vue";
export default {
  components: {
    Modal,
    BaseButton,
    BaseRadio,
    FileTextIcon,
    MusicIcon,
    BaseCheckbox,
  },

  data() {
    return {
      type: ExportType.standard,
      withAudio: false,
      showModal: false,
      loading: false,
      options: [
        {
          name: "Standard Transcript",
          extension: "MS Word file (.docx)",
          icon: FileTextIcon,
          value: ExportType.standard,
        },
        {
          name: "Non-formatted Transcript",
          extension: "MS Word file (.docx)",
          icon: FileTextIcon,
          value: ExportType.nonFormatted,
        },
        {
          name: "Numbered Transcript",
          extension: "MS Word file (.docx)",
          icon: FileTextIcon,
          value: ExportType.alt,
        },
        {
          name: "Only Audio",
          extension: "Audio file (.mp3)",
          icon: MusicIcon,
          value: ExportType.audio,
        },
      ],
    };
  },
  computed: {
    withAudioDisabled() {
      return this.type === ExportType.audio;
    },
  },
  methods: {
    emit() {
      this.$emit("export", {type: this.type, withAudio: this.withAudio});
      this.showModal = false;
    },
  },
};
</script>

<style>
.transcript-export--modal .modal-dialog {
  max-width: 600px !important;
}
.transcript-export--active {
  background: #1f3300;
  border: 1px solid #426511 !important;
}
</style>
