import moment from "moment";
import {isDefined} from "../api/helpers";
import * as zip from "@zip.js/zip.js";

export const ExportType = {
  standard: "standard",
  nonFormatted: "non-formatted",
  alt: "alternate",
  audio: "audio",
};

export const buildNonFormattedTranscriptionHtml = (transcriptionName, caseNames, speakers, userMap, actorMap) => {
  const paragraphs = speakers.map((sp) => {
    let sName = `Speaker: ${sp.speakerTag || ""}`;
    if (isDefined(sp.speakerUserId ) && userMap[sp.speakerUserId]) {
      sName = userMap[sp.speakerUserId].fullName;
    } else if (isDefined(sp.speakerActorId ) && actorMap[sp.speakerActorId]) {
      sName = actorMap[sp.speakerActorId].fullName;
    }

    // IF redaction of the same length is desired, then instead of -- below use : s.c.replace(/./gmi, "*")
    // Assuming not, because it would leave clues as to what was removed
    const remainingContent = sp.content.map((s) => {
      return {c: s.content, r: s.redacted};
    }).map((s) => s.r ? " --" : s.c).join("").trim(); // .split(" ").reverse();

    return `<div><b>${sName}</b></div><div>${remainingContent}</div>`;
  });

  // Build the HTML
  const transcriptHTML = `<p>${paragraphs.join("</p><p>")}</p>`;

  const style = `
body {
display: block;
margin: 8px;
}
.transcript {
font-family: 'Times New Roman';
font-size: 16px;
letter-spacing: 0.1em;
line-height: 2em;
max-width: 46em;
}

p.MsoHeader, li.MsoHeader, div.MsoHeader{
  margin:0in;
  margin-top:.0001pt;
  mso-pagination:widow-orphan;
  tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
  margin:0in 0in 1in 0in;
  margin-bottom:.0001pt;
  mso-pagination:widow-orphan;
  tab-stops:center 3.0in right 6.0in;
}
.footer {
  font-size: 9pt;
}

table#hdrFtrTbl
{
  margin:0in 0in 0in 900in;
  width:1px;
  height:1px;
  overflow:hidden;
  display:none;
}
    `;
  const exportedAt = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC";
  const hdrFtrTable = `<table id='hdrFtrTbl' border='1' cellspacing='0' cellpadding='0'><tr style='height:1pt;mso-height-rule:exactly'><td><div style='mso-element:header' id="h1"><p class="MsoHeader"><table border="0" width="100%"><tr><td>${caseNames.length > 0 ? `Case(s): ${caseNames}` : ""}</td></tr><tr><td>Transcript: ${transcriptionName}</td></tr></table></p></div></td><td><div style='mso-element:footer' id="f1"><p class="MsoFooter"><table width="100%" border="0" cellspacing="0" cellpadding="0"><tr><td align="center" class="footer"><g:message code="offer.letter.page.label"/><span style='mso-field-code: PAGE '></span> of <span style='mso-field-code: NUMPAGES '></span></td></tr><tr><td align='center' style='font-size:12px'>Exported at: ${exportedAt}</td></tr></table></p></div></td></tr></table>`;
  const html = `<!DOCTYPE html>\n<html lang="en">\n<head>\n<title>${transcriptionName}</title>\n<style>${style}</style></head><body><div class="transcript">\n${transcriptHTML}\n</div>${hdrFtrTable}</body></html>`;
  return html;
};

export const exportNonFormattedTranscriptionContent = (transcriptionName, caseNames, speakers, userMap, actorMap) => {
  const html = buildNonFormattedTranscriptionHtml(transcriptionName, caseNames, speakers, userMap, actorMap);
  exportToDoc(html, transcriptionName);
};

export const buildAltFormattedTranscriptionHtml = (
  transcriptionName, caseNames, speakers, userMap, actorMap, upload
) => {
  const legendEntries = [];
  const tab = "&#09;";
  const emptyLine = "<p>&nbsp;</p>";
  const paragraphs = speakers.map((sp) => {
    let sName = `${sp.speakerTag || ""}`;
    if (isDefined(sp.speakerUserId ) && userMap[sp.speakerUserId]) {
      sName = userMap[sp.speakerUserId].fullName;
    } else if (isDefined(sp.speakerActorId ) && actorMap[sp.speakerActorId]) {
      sName = actorMap[sp.speakerActorId].fullName;
    }

    const ssName = sName.length === 0 ? "U_" : sName.split(" ").map((p) => p.trim()[0] || "").join("").toUpperCase();
    const exists = legendEntries.filter((le) => le.startsWith(ssName)).length;
    if (!exists) legendEntries.push(`${ssName}: ${sName}`);

    const remainingContent = sp.content.map((s) => {
      return {c: s.content, r: s.redacted};
    }).map((s) => s.r ? ` ${s.c.trim().replace(/./gmi, "-")}` : s.c).join("").trim(); // .split(" ").reverse();

    return `<p><b><span>${ssName}</span></b><span>:</span><span><span style="mso-tab-count:1">${tab}</span></span><span>${remainingContent}<span></p>`;
  });
  const CUSTOMER_NAME = "OFFICE";
  // Build the HTML
  // Title
  const typed = `<p class="centered">${upload?.uploadType?.toUpperCase() ?? "AUDIO"}</p>`;

  // Key Value Metadata
  const case_ = isDefined(upload) && Array.isArray(upload.cases) ? upload.cases.pop() : null;
  const caseUsers = case_?.userAttributions
    .map((ua) => ua.userId).filter((i) => isDefined(i)).map((i) => userMap[i].fullName).reverse();
  const vm = {
    "CASE NO.:": {v: case_?.title, s: `${tab}${tab}${tab}`},
    "CASE NAME:": {v: case_?.name, s: `${tab}${tab}`},
    "RECORDING DATE:": {v: upload.eventOn ? upload.eventOn.split("T")[0] : null, s: `${tab}`},
    "RECORDING TIME:": {v: upload.eventOn ? upload.eventOn.split("T").pop().split("Z")[0] : null, s: `${tab}${tab}`},
    "SOURCE FILE:": {v: upload.originalFileName, s: `${tab}${tab}`},
    "DEPUTY D.A.:": {v: caseUsers.pop(), s: `${tab}${tab}`},
    "D.A. UNIT:": {v: "s", s: `${tab}${tab}${tab}`},
  };
  const vms = Object.keys(vm).map((k) => `<p><b><span>${k}</span></b><span><span>${vm[k].s}</span></span><span>${vm[k].v}</span></p>`).join("\n");
  // Speaker Legend
  const les = legendEntries.map((le) => `<p class="centered"><b><span>${le.split(": ")[0]}</span></b><span>:&nbsp;${le.split(": ")[1]}</span></p>`).join("\n");
  const legend = `<p class="centered">LEGEND:</p>\n${les}`;
  // Extra Meta
  const transcribedBy = `<p class="centered">TRANSCRIPT PROVIDED BY</p>\n<p class="centered">ETHOS ON BEHALF OF ${CUSTOMER_NAME}</p>`;

  // File.
  const transcriptHTML = `${typed}\n${emptyLine}\n${vms}\n${emptyLine}\n${legend}\n${emptyLine}\n${transcribedBy}\n${emptyLine}\n${paragraphs.join("\n")}`;

  const style2 = `
body {
  display: block;
  tab-interval: 36.0pt;
  word-wrap: break-word;
}
div.WordSection1
{
  page:WordSection1;
}
.centered {
  text-align: center;
}
p {
  mso-style-unhide:no;
  mso-style-qformat:yes;
  mso-style-parent:"";
  margin-top:0cm;
  margin-right:0cm;
  margin-bottom:0cm;
  margin-left:0cm;
  line-height:150%;
  mso-pagination: widow-orphan;
  font-size:14.0pt;
  font-family:"Times New Roman",serif;
  mso-ascii-font-family:"Times New Roman";
  mso-ascii-theme-font:minor-latin;
  mso-fareast-font-family:"Times New Roman";
  mso-fareast-theme-font:minor-fareast;
  mso-hansi-font-family:"Times New Roman";
  mso-hansi-theme-font:minor-latin;
  mso-bidi-font-family:"Times New Roman";
  mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:EN-GB;
  mso-fareast-language:JA;
}
p.hang {
  margin-left:42.5pt;
  text-indent:-42.5pt;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter {
  mso-style-priority:99;
  margin:0cm;
  mso-pagination:widow-orphan;
  tab-stops:center 234.0pt right 468.0pt;
  font-size:12.0pt;
  font-family:"Times New Roman",serif;
  mso-ascii-font-family:"Times New Roman";
  mso-ascii-theme-font:minor-latin;
  mso-fareast-font-family:"Times New Roman";
  mso-fareast-theme-font:minor-fareast;
  mso-hansi-font-family:"Times New Roman";
  mso-hansi-theme-font:minor-latin;
  mso-bidi-font-family:"Times New Roman";
  mso-bidi-theme-font:minor-bidi;
  mso-ansi-language:EN-GB;
  mso-fareast-language:JA;
}
table#hdrFtrTbl
{
    margin:0in 0in 0in 900in;
    width:1px;
    height:1px;
    overflow:hidden;
    display:none;
}
@page {
  mso-footnote-separator: fs;
  mso-footnote-continuation-separator: fcs;
  mso-endnote-separator: es;
  mso-endnote-continuation-separator: ecs;
}
@page WordSection1 {
  size:595.3pt 841.9pt;
  margin:72.0pt 72.0pt 72.0pt 72.0pt;
  mso-header-margin:36.0pt;
  mso-footer-margin:36.0pt;
  mso-line-numbers-count-by:1;
  mso-header: h1;
  mso-footer: f1;
  mso-paper-source:0;
}
`;

  const htmlPrefix = "<html lang=\"en\" xmlns:v=\"urn:schemas-microsoft-com:vml\" xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:w=\"urn:schemas-microsoft-com:office:word\" xmlns:m=\"http://schemas.microsoft.com/office/2004/12/omml\" xmlns=\"http://www.w3.org/TR/REC-html40\">";
  const head = `<head>\n<meta http-equiv=Content-Type content="text/html; charset=windows-1252">\n<meta name=ProgId content=Word.Document>\n<title>${transcriptionName}</title>\n<style>${style2}</style></head>`;
  const htmlSuffix = "</html>";
  const exportedAt = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC";
  const hdrFtrTable = `<table id='hdrFtrTbl' border='0' cellspacing='0' cellpadding='0'>
  <tr style='height:1pt;mso-height-rule:exactly'>
    <td>
      <div style='mso-element:footer' id="f1">
        <p class="MsoFooter">
          <table width="100%" border="0" cellspacing="0" cellpadding="0">
            <tr>
              <td align="center" class="footer">
                <g:message code="offer.letter.page.label"/>
                <span style='mso-field-code: PAGE '></span> of <span style='mso-field-code: NUMPAGES '></span>
              </td>
            </tr>
            <tr>
              <td align='center' style='font-size:12px'>Exported at: ${exportedAt}</td>
            </tr>
          </table>
        </p>
      </div>
    </td>
  </tr>
</table>`;
  const html = `<!DOCTYPE html>\n${htmlPrefix}\n${head}\n<body><div class=WordSection1>\n${transcriptHTML}\n</div>\n${hdrFtrTable}\n</body>\n${htmlSuffix}`;
  return html;
};

export const buildStandardTranscriptionHtml = (transcriptionName, caseNames, speakers, userMap, actorMap) => {
  const maxCharCount = 47;
  const firstCharCount = 10;
  const maxLineCount = 25;

  // Start the page code
  let charCount = 0;
  const pages = [];
  let lines = [];
  let lineCopy = "";
  const nameStyle = " style=''";

  const charStart = speakers[0]?.content[0]?.start;
  const lineStart = charStart > 0 ? Math.ceil(charStart / maxCharCount + 1) : 1;

  speakers.forEach((sp) => {
    let sName = `Speaker: ${sp.speakerTag || ""}`;
    if (isDefined(sp.speakerUserId ) && userMap[sp.speakerUserId]) {
      sName = userMap[sp.speakerUserId].fullName;
    } else if (isDefined(sp.speakerActorId ) && actorMap[sp.speakerActorId]) {
      sName = actorMap[sp.speakerActorId].fullName;
    }

    // Make it uppercased
    sName = sName.toUpperCase() + ":";
    sName = sName.split(" ").reverse();

    // IF redaction of the same length is desired, then instead of -- below use : s.c.replace(/./gmi, "*")
    // Assuming not, because it would leave clues as to what was removed
    const remainingContent = sp.content.map((s) => {
      return {c: s.content, r: s.redacted};
    }).map((s) => s.r ? " --" : s.c).join("").trim().split(" ").reverse();

    // Close this line - need a new one for the speaker
    if (charCount > 0) {
      lines.push(lineCopy);
      lineCopy = "";
      charCount = 0;
    }

    if (lines.length >= maxLineCount) {
      // Reset - close the page
      pages.push({lines});
      lines = [];
      charCount = 0;
    }

    let isFirst = true;
    while (sName.length > 0) {
      // Each speaker is a new line
      charCount = 0;

      let OOB = false; // OutOfBounds
      let nameForLine = "";

      // Loop and stitch the name together as we go
      while (!OOB && sName.length > 0) {
        // First needs an indent
        const remainingSpace = (isFirst ? maxCharCount - firstCharCount : maxCharCount) - nameForLine.length;
        const name = sName.pop();

        // We're at the start of a new line... if its too long just split it
        if (nameForLine.length === 0) {
          // Split it - if its too long
          let nameToAdd = name.slice(0, remainingSpace);
          if (nameToAdd.length < name.length) {
            // replace last char with hyphen and split
            nameToAdd = nameToAdd.slice(0, nameToAdd.length - 1) + "-";
            // Add any remainder back and call it out of bounds
            sName.push(name.slice(nameToAdd.length - 1));
            OOB = true;
          }
          nameForLine = nameToAdd;
        } else if (name.length + 1 <= remainingSpace) {
          // We fit - great - just append;
          nameForLine += ` ${name}`;
        } else {
          // next name part is too long; add in and move on.
          sName.push(name);
          OOB = true;
        }
      }

      // Now stitch
      lineCopy += `<b${isFirst ? nameStyle : ""}>${nameForLine}</b>`;
      charCount += (isFirst ? firstCharCount : 0) + nameForLine.length;

      // Close line IF sName has more
      if (sName.length > 0 || charCount === maxCharCount) {
        // End of the line; bump to next
        lines.push(lineCopy);
        lineCopy = "";

        if (lines.length >= maxLineCount) {
          // End of page; bump to next
          pages.push({lines});
          lines = [];
        }
        charCount = 0;
      }

      isFirst = false;
    }

    // Add a space if we're not at line end
    for (let x = 0; x < 2; x++) {
      if (charCount !== 0 && charCount + 1 < maxCharCount) {
        charCount++;
        lineCopy += "&nbsp;";
      }
    }

    // Apply the remaining content
    while (remainingContent.length > 0) {
      const next = remainingContent.pop();
      if (next.length < maxCharCount - charCount - 1) {
        // Just add it
        lineCopy += ` ${next}`;
        charCount += next.length + 1;
      } else {
        // End of the line; bump to next
        if (charCount > 0) {
          lines.push(lineCopy);
          lineCopy = "";
          charCount = 0;
        }

        if (lines.length >= maxLineCount) {
          // End of page; bump to next
          pages.push({lines});
          lines = [];
        }

        // Open the line
        if (next.length <= maxCharCount) {
          // We can add - because it fits
          lineCopy += next;
          charCount = next.length;
        } else {
          // Fit what we can
          lineCopy += next.slice(0, maxCharCount - 1) + "-";

          // Push remaining back
          remainingContent.push(next.slice(maxCharCount - 1));

          charCount = maxCharCount;
        }
      }

      if (charCount >= maxCharCount) {
        // End of the line; bump to next
        lines.push(lineCopy);
        lineCopy = "";
        charCount = 0;
        if (lines.length >= maxLineCount) {
          // End of page;
          pages.push({lines});
          lines = [];
        }
      }
    }
  });

  // append the closing tag for the line
  if (charCount > 0) lines.push(lineCopy);
  // append the closing tag for the page
  if (lines.length > 0) {
    // Fill page
    while (lines.length < maxLineCount) {
      lines.push(" ");
    }
    // Close page
    pages.push({lines});
  }

  // Build the HTML
  let transcriptHTML = "";
  for (let p = 0; p < pages.length; p++) {
    // transcriptHTML += `<div class="page" data-p="${p + 1}">`;
    for (let l = 0; l < pages[p].lines.length; l++) {
      transcriptHTML += `<div class="line" data-l="${l + 1}">${pages[p].lines[l]}</div>`;
    }
    // transcriptHTML += `<div class="page-count">${p + 1} of ${pages.length}</div></div>`;
  }

  const style = `
body {
  display: block;
  margin: 8px;
}
.transcript {
  font-family: 'Times New Roman';
  font-size: 16px;
  letter-spacing: 0.1em;
  line-height: 2em;
  max-width: 46em;
}
.page {
  border: 2px solid black;
  counter-reset: line;
  margin: 1em 1em 6em 1em;
  padding: 1em;
  page-break-after: always;
  position: relative;
}
.page-count {
    position: absolute;
    bottom: -3em;
    left: 0;
    right: 0;
    text-align: center;
}
.line {
  counter-increment: line;
  margin-left: 5em;
  min-height: 2em;
  position: relative;
}

.line:before {
  content: counter(line);
  left: -5em;
  position: absolute;
  text-align: right;
  width: 2em;
}

p.MsoHeader, li.MsoHeader, div.MsoHeader{
    margin:0in;
    margin-top:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
p.MsoFooter, li.MsoFooter, div.MsoFooter{
    margin:0in 0in 1in 0in;
    margin-bottom:.0001pt;
    mso-pagination:widow-orphan;
    tab-stops:center 3.0in right 6.0in;
}
.footer {
    font-size: 9pt;
}

table#hdrFtrTbl
{
    margin:0in 0in 0in 900in;
    width:1px;
    height:1px;
    overflow:hidden;
    display:none;
}

@page transcript
{size:8.5in 11.0in;
margin:1in 1in 1in 1in;
mso-header-margin:.5in;
mso-footer-margin:.5in;
mso-line-numbers-count-by:1;
mso-line-numbers-restart:continuous;
mso-line-numbers-start:${lineStart};
mso-header: h1;
mso-footer: f1;
mso-first-header: fh1;
mso-first-footer: ff1;
mso-paper-source:0;
}
div.transcript
  {page:transcript;}
      `;
  const exportedAt = moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss") + " UTC";
  const hdrFtrTable = `<table id='hdrFtrTbl' border='1' cellspacing='0' cellpadding='0'><tr style='height:1pt;mso-height-rule:exactly'><td><div style='mso-element:header' id="h1"><p class="MsoHeader"><table border="0" width="100%"><tr><td>${caseNames.length > 0 ? `Case(s): ${caseNames}` : ""}</td></tr><tr><td>Transcript: ${transcriptionName}</td></tr></table></p></div></td><td><div style='mso-element:footer' id="f1"><p class="MsoFooter"><table width="100%" border="0" cellspacing="0" cellpadding="0"><tr><td align="center" class="footer"><g:message code="offer.letter.page.label"/><span style='mso-field-code: PAGE '></span> of <span style='mso-field-code: NUMPAGES '></span></td></tr><tr><td align='center' style='font-size:12px'>Exported at: ${exportedAt}</td></tr></table></p></div></td></tr></table>`;
  const html = `<!DOCTYPE html>\n<html lang="en">\n<head>\n<title>${transcriptionName}</title>\n<style>${style}</style></head><body><div class="transcript">\n${transcriptHTML}\n</div>${hdrFtrTable}</body></html>`;
  // 816px wide
  // 1064px length
  // const c = document.createElement("a");
  // c.style.display = "none";
  // c.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(html));
  // c.setAttribute("download", transcriptionName + ".html");
  // document.body.appendChild(c);
  // c.click();
  // document.body.removeChild(c);
  return html;
};

export const exportTranscriptionContent = (transcriptionName, caseNames, speakers, userMap, actorMap) => {
  const html = buildStandardTranscriptionHtml(transcriptionName, caseNames, speakers, userMap, actorMap);
  exportToDoc(html, transcriptionName);
};

export const exportToDoc = (htmlContent, filename) => {
  const html = htmlContent;
  const blob = new Blob(["\ufeff", html], {
    type: "application/msword",
  });
  // Specify link url
  const url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
  // Specify file name
  filename = filename ? filename + ".doc" : "document.doc";
  // Create download link element
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  if (navigator.msSaveOrOpenBlob ) {
    navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    // Create a link to the file
    downloadLink.href = url;
    // Setting the file name
    downloadLink.download = filename;
    // triggering the function
    downloadLink.click();
  }
  document.body.removeChild(downloadLink);
};

export const downloadDoc = (html, filename) => {
  const name = filename ? filename + ".doc" : "document.doc";
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  // Create a link to the file
  const url = "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);
  downloadLink.href = url;
  // Setting the file name
  downloadLink.download = name;
  // triggering the function
  downloadLink.click();
};

export const exportZip = async (contents) => {
  const zipWriter = new zip.ZipWriter(new zip.BlobWriter("application/zip"));
  const entries = [];
  contents.forEach((c) => {
    const name = c.filename || entries.length;
    if (c.html) {
      entries.push(
        zipWriter.add(`${c.parentName ? `${c.parentName}/` : ""}${name}/${name ? name + ".doc" : "document.doc"}`, new zip.TextReader(c.html))
      );
    }
    if (c.audio) {
      entries.push(
        zipWriter.add(`${c.parentName ? `${c.parentName}/` : ""}${name}/${c.audioFileName}`, new zip.BlobReader(c.audio))
      );
    }
  });
  await Promise.all(entries);
  return zipWriter.close();
};

export const downloadZip = (zipBlob) => {
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);

  // Create a link to the file
  downloadLink.href = URL.createObjectURL(zipBlob);
  // Setting the file name
  downloadLink.download = "ethos.zip";
  // triggering the function
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const convertTranscriptDomToText = (docFragment, addExcludedClasses) => {
  const excludedClasses = ["speaker-time-series"].concat(addExcludedClasses ?? []);
  const speakerClass = "speaker";
  const redactedClass = "redacted";
  const walker = document.createTreeWalker(
    docFragment,
    NodeFilter.SHOW_TEXT | NodeFilter.SHOW_ELEMENT,
    {
      acceptNode(node) {
        if (node.nodeType === Node.ELEMENT_NODE) {
          return excludedClasses.some((className) => node.classList.contains(className)) ?
            NodeFilter.FILTER_REJECT :
            NodeFilter.FILTER_SKIP;
        }
        return NodeFilter.FILTER_ACCEPT;
      },
    }
  );

  let text = "";
  let isInRedaction = false;
  let nextNode = walker.nextNode();
  while (nextNode != null) {
    const currentNode = walker.currentNode;

    if (currentNode.nodeType === Node.TEXT_NODE) {
      let parent = currentNode.parentNode;
      let addNewLine = false;
      let isRedacted = false;

      while (parent != null && parent !== docFragment) {
        if (parent.classList && parent.classList.contains(speakerClass)) {
          addNewLine = true;
        }
        parent = parent.parentNode;
      }

      parent = currentNode.parentNode;
      while (parent != null && parent !== docFragment) {
        isRedacted = parent.classList && parent.classList.contains(redactedClass);
        if (isRedacted) break;
        parent = parent.parentNode;
      }

      let nodeText = currentNode.textContent.trimStart();
      if (isRedacted) {
        // nodeText = isInRedaction ? nodeText : `[redacted]${nodeText}`;
        nodeText = "";
        isInRedaction = true;
      } else {
        if (isInRedaction) {
          if (addNewLine) {
            // nodeText = `[/redacted]\n${nodeText}\n`;
            nodeText = `\n${nodeText}\n`;
          } else {
            // nodeText = `${nodeText}[/redacted]`;
            nodeText = "";
          }
        } else {
          nodeText = addNewLine ? `\n${nodeText}\n` : nodeText;
        }
        isInRedaction = false;
      }
      text += nodeText;
    }
    nextNode = walker.nextNode();
    if (nextNode == null) {
      // if (isInRedaction) {
      //   text += "[/redacted]";
      // }
    }
  }
  return text;
};

export const transcriptionMetadataToSpans = (metadata, content) => {
  if (!metadata || !metadata.entries || !content) return [];
  const entries = metadata.entries.map((e) => {
    return {
      content: content.slice(e.s, e.e),
      start: e.s,
      end: e.e,
      startOffset: stringTimes(e.st, e.sTm),
      endOffset: stringTimes(e.et, e.eTm),
      confidence: e.c,
      redacted: e.r === true,
    };
  });
  return entries;
};

export const transcriptionMetadataToSpeakers = (metadata, content, spans) => {
  if (!metadata || !metadata.speakers || !content) return [];
  let lastIndex = content.length;

  const speakers = metadata.speakers.slice().reverse().map((s) => {
    const slice = spans.filter((sp) => sp.start >= s.s && sp.end <= lastIndex);
    lastIndex = s.s;
    const toReturn = {
      content: slice,
      rawContent: slice.reduce((a, e) => a + e.content, ""),
      speakerPos: s.s,
      speakerActorId: null,
      speakerUserId: null,
      speakerTag: s.tag,
    };
    if (isDefined(s.uid)) {
      toReturn.speakerUserId = s.uid;
    } else if (isDefined(s.aid)) {
      toReturn.speakerActorId = s.aid;
    }
    return toReturn;
  }).reverse();
  return speakers;
};

export const stringTimes = (s, m) => {
  let seconds = typeof s === "string" ? parseInt(s, 10) : s;
  if (isNaN(seconds)) seconds = 0;
  let millis = typeof m === "string" ? parseInt(m, 10) : m;
  if (isNaN(millis)) millis = 0;
  return seconds + (millis / 1000);
};


/**
 * Clip Exporting
 */
export const downloadClipTranscript = async (
  loadPeople, getClip, downloadClip,
  userMap, actorMap,
  uploadId, clipId, type, withAudio
) => {
  await loadPeople();
  const content = await getClipTranscriptContent(
    getClip, downloadClip,
    userMap, actorMap,
    uploadId, clipId, type, withAudio
  );

  if (content.audio) {
    const zipBlob = await exportZip([content]);
    if (zipBlob) downloadZip(zipBlob);
  } else {
    downloadDoc(content.html, content.filename);
    return null;
  }
};

export const getClipTranscriptContent = async (
  getClip, downloadClip,
  userMap, actorMap,
  uploadId, clipId, type, withAudio
) => {
  const clip = await getClip(uploadId, clipId);
  const audio = withAudio ? await downloadClip(uploadId, clipId) : null;
  return buildExportClipContent({clip, audio}, type, userMap, actorMap);
};

export const buildExportClipContent = (data, type, userMap, actorMap) => {
  const clip = data.clip;
  if (!clip || !clip.metadata) throw new Error("Uplaod not found");
  const name = clip.title;
  const clipContent = clip.metadata && clip.metadata.entries && clip.metadata.entries.length > 0 ? `${"".padStart(clip.metadata.entries[0].s, " ")}${clip.content}` : clip.content;
  const parentName = data.upload ? data.upload.name : clip.upload ? clip.upload.name : clip.uploadId;
  return buildExportContent(
    data.audio, type, userMap, actorMap,
    name, parentName, [],
    clip.metadata, clipContent, `${clip.title}.wav`,
    data.upload ? data.upload : clip.upload
  );
};

/**
 * Upload Exporting
 */
export const downloadUploadTranscript = async (
  loadPeople, getUploadById, downloadUpload,
  userMap, actorMap,
  uploadId, type, withAudio
) => {
  await loadPeople();
  const content = await getUploadTranscriptContent(
    getUploadById, downloadUpload,
    userMap, actorMap,
    uploadId, type, withAudio
  );
  if (content.audio) {
    const zipBlob = await exportZip([content]);
    if (zipBlob) downloadZip(zipBlob);
  } else {
    downloadDoc(content.html, content.filename);
    return null;
  }
};

export const getUploadTranscriptContent = async (
  getUploadById, downloadUpload,
  userMap, actorMap,
  uploadId, type, withAudio
) => {
  const upload = await getUploadById(uploadId, true);
  const audio = withAudio ? await downloadUpload(uploadId, true) : null;
  return buildExportUploadContent({upload, audio}, type, userMap, actorMap);
};

export const buildExportUploadContent = (data, type, userMap, actorMap) => {
  const upload = data.upload;
  if (!upload || !upload.transcription || !upload.transcription.metadata) throw new Error("Upload not found");
  const name = upload.name || upload.transcription.name;
  const cases = upload.cases.map((x) => x.name);
  return buildExportContent(
    data.audio, type, userMap, actorMap,
    name, null, cases,
    upload.transcription.metadata, upload.transcription.content, upload.originalFileName,
    upload
  );
};

/**
 * Common formatting of downloadable content
 */
const buildExportContent = (
  audio, type, userMap, actorMap,
  name, parentName, cases,
  metadata, transcriptionContent, fileName, upload
) => {
  const speakers = transcriptionMetadataToSpeakers(
    metadata,
    transcriptionContent,
    transcriptionMetadataToSpans(metadata, transcriptionContent)
  );
  const content = {filename: name, parentName};
  if (type === ExportType.nonFormatted) {
    content.html = buildNonFormattedTranscriptionHtml(name, cases, speakers, userMap, actorMap);
  } else if (type === ExportType.alt) {
    content.html = buildAltFormattedTranscriptionHtml(name, cases, speakers, userMap, actorMap, upload);
  } else {
    content.html = buildStandardTranscriptionHtml(name, cases, speakers, userMap, actorMap);
  }

  if (audio) {
    content.audio = audio;
    content.audioFileName = fileName;
  }
  return content;
};
