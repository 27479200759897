const getters = {
  established: (state, getters) => {
    // Make sure that we've called in the user has a valid active role
    return state.hasCalled && (
      getters.isRoot ||
      getters.isAdmin ||
      getters.isEditor ||
      getters.isViewOnly
    );
  },
  userId: (state) => {
    return state.id;
  },
  userEmail: (state) => {
    return state.email;
  },
  userFullName: (state) => {
    return state.fullName;
  },
  userFirstName: (state) => {
    return state.firstName;
  },
  userLastName: (state) => {
    return state.lastName;
  },
  userProfilePhotoUrl: (state) => {
    return state.photoUrl;
  },
  isRoot: (state) => {
    return state.roles.includes("ROOT");
  },
  isAdmin: (state) => {
    return state.roles.includes("ADMIN");
  },
  isEditor: (state) => {
    return state.roles.includes("EDITOR");
  },
  isViewOnly: (state) => {
    return state.roles.includes("VIEWER");
  },
  loggedInAt: (state) => {
    return state.loggedInAt;
  },
};

export default getters;
