<template>
  <div
    v-if="transcript"
    class="audio-transcript flex-grow-1"
  >
    <div class="d-flex col h-100">
      <div class="transcript col-9 d-flex flex-column">
        <div
          v-if="$refs.transcription && $refs.transcription.isEditMode && !hasRevision"
          class="edit-toolbar"
        >
          <base-button
            class="mr-2"
            size="xxs"
            icon
            simple
            @click="() => $refs.transcription && $refs.transcription.saveEdits()"
          >
            <save-icon size="1.1x" />
          </base-button>
          <base-button
            size="xxs"
            icon
            simple
            type="danger"
            @click="() => $refs.transcription && $refs.transcription.cancelEdits()"
          >
            <x-icon size="1.1x" />
          </base-button>
        </div>
        <tokenized-transcription-content
          ref="transcription"
          :content="transcriptContent"
          :metadata="transcriptMetadata"
          :transcription-id="transcriptId"
          :transcription-name="transcriptName"
          :upload-id="uploadId"
          :incidents="transciptionIncidents"
          :current-playtime="currentPlaytime"
          :keyword-indices="keywordIndices"
          :keyword-current-index="keywordCurrentIndex"
          :code-word-indices="codeWordIndices"
          :clip="clip"
          :signal-value="removeSignal"
          :unclear-value="removeUnclear"
          :class="transcriptionClass"
          :disabled="hasRevision"
          @updated="transcriptUpdated"
          @incident="updatedIncident"
          @seek="seekTo"
          @contentUpdated="contentUpdated"
          @selectSpeaker="selectSpeaker"
          @index="(ind) => $refs.contentSearch && $refs.contentSearch.setCurrent(ind)"
          @updatedClip="updatedClip"
          @spanPlaying="maybeScrollView"
        />
        <template
          v-if="hasSignedUrl"
        >
          <div
            v-if="isAutoScrollPaused == true && $refs.controls && $refs.controls.isPlaying == true"
            class="as-indicator"
          >
            <base-button
              size="xxs"
              class="as-indicator-button mt-1"
              :loading="loading"
              @click="resumeAutoScroll"
            >
              <pause-icon
                size="2x"
                class="pr-2"
              />
              Auto scroll paused
              <x-icon
                size="1.7x"
                class="pl-2"
              />
            </base-button>
          </div>
          <div class="h-divider mt-1" />
          <div class="audio-player mt-2">
            <custom-media-controls
              v-if="hasSignedUrl"
              ref="controls"
              :key="transcriptSignedUrl"
              :sources="[{
                id: transcriptId,
                source: transcriptSignedUrl,
                isAudio: true,
                offset: 0,
              }]"
              :play-back="transcriptPlayback"
              :incidents="mappedIncidents"
              :signals="codeWordTimeStamps"
              :can-clip="!isClip && !hasRevision"
              :clip-offset="clip ? clip.startOffset : -1"
              @created="appendMediaElement"
              @destroying="removeMediaElement"
              @time="updateTime"
              @incident="(i) => $refs.transcription && $refs.transcription.editIncident(i)"
              @onClipRange="(i) => $refs.transcription && $refs.transcription.onClipRange(i)"
              @fontChange="fontSize = $event"
              @seekTo="bypassPausedScroller = true"
            />
          </div>
        </template>
      </div>
      <div
        v-if="hasTranscript"
        class="col-3 d-flex flex-column overflow-auto p-0"
      >
        <el-tabs
          v-model="activeName"
          type="border-card"
          class="flex-grow-1 pt-3"
        >
          <el-tab-pane
            label="Signals"
            name="signals"
          >
            <transcription-signals
              :key="transcriptContentKey"
              :transcript-content="transcriptContent"
              :metadata="transcriptMetadata"
              class="col"
              @indices="(i) => codeWordIndices = i"
              @current="(i) => codeWordCurrentIndex = i"
              @removeSignal="onRemoveSignal"
            />
          </el-tab-pane>

          <el-tab-pane
            label="Keyword Search"
            name="keywordSearch"
          >
            <content-search
              ref="contentSearch"
              :key="transcriptContentKey"
              :transcript-content="transcriptContent"
              class="col"
              @scrollToNext="scrollToNext"
              @indices="(i) => keywordIndices = i"
              @current="(i) => keywordCurrentIndex = i"
            />
          </el-tab-pane>

          <el-tab-pane
            v-if="false"
            label="Revisions"
            name="revisions"
          >
            <revisions
              ref="contentSearch"
              :key="transcriptContentKey"
              :transcript="transcript"
              :displayed-revision="hasRevision ? transcriptContentKey : null"
              class="col"
              @display="displayRevision"
              @restored="applyTranscriptUpdate"
            />
          </el-tab-pane>

          <el-tab-pane
            v-if="false"
            :label="`Unclear Words(${unclearWordsCount})`"
            name="unclearWords"
          >
            <unclear-words
              :key="transcriptContentKey"
              :content="transcriptContent"
              :metadata="transcriptMetadata"
              :transcription-id="transcriptId"
              class="col"
              @count="setUnclearWordsCount"
              @removeUnclear="onRemoveUnclear"
            />
          </el-tab-pane>
        </el-tabs>
        <div class="h-divider mx-4 mb-4" />
        <div class="assistant-bottom">
          <div class="row mx-5 align-items-baseline">
            <div class="avatar avatar-sm">
              {{ transcriptMetadata.confidence }}
            </div>
            <div class="avatar-label">
              Confidence Score
            </div>
          </div>
          <div class="row ml-4 mr-1 align-items-baseline d-none">
            <base-button
              type="primary"
              style="font-weight:400;"
              :disabled="isClip || hasRevision"
              link
              @click="() => $refs.uploadsForm.display()"
            >
              Reprocess Analytics
            </base-button>
          </div>
        </div>
      </div>
    </div>
    <add-new-uploads-modal
      ref="uploadsForm"
      :upload-id="uploadId"
      :upload-name="uploadName"
      just-workflows
      header="ReProcess !!!"
    />
    <add-people-modal
      ref="speakerForm"
      :case-id="caseId"
      :upload-id="uploadId"
      associate
      @associated="speakerChanged"
      @created="speakerChanged"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {getAudioTranscript, getClip, getUploadIncidents} from "../../../../api";
import TokenizedTranscriptionContent from "../AudioTranscript/TokenizedTranscriptionContent.vue";
import UnclearWords from "./UnclearWords.vue";
import ContentSearch from "./ContentSearch.vue";
import Revisions from "./Revisions.vue";
import TranscriptionSignals from "./TranscriptionSignals.vue";
import CustomMediaControls from "./CustomMediaControls.vue";
import AddPeopleModal from "../../../../components/DashboardV2/Case/AddPeopleModal.vue";
import AddNewUploadsModal from "../AddNewUploadsModal.vue";
import "src/assets/sass/custom/audio-transcript.scss";
import {isDefined} from "../../../../api/helpers";
import {ethosRouteNames} from "../../../../routes/routeNames";
import {PauseIcon, XIcon, SaveIcon} from "vue-feather-icons";

export default {
  name: "audio-transcript",
  components: {
    TokenizedTranscriptionContent,
    ContentSearch,
    ContentSearch,
    Revisions,
    UnclearWords,
    AddNewUploadsModal,
    AddPeopleModal,
    TranscriptionSignals,
    CustomMediaControls,
    PauseIcon,
    XIcon,
    SaveIcon,
  },
  data() {
    return {
      transcript: null,
      revision: null,
      clip: null,
      loading: false,
      hasTranscript: false,
      updateInterval: null,
      activeName: "keywordSearch",
      unclearWordsCount: 0,
      originalSpeaker: null,
      selectSpeakerCallback: () => {},
      currentPlaytime: 0,
      incidents: [], // TODO: - Add these to store
      keywordIndices: [],
      keywordCurrentIndex: -1,
      codeWordIndices: [],
      codeWordCurrentIndex: -1,
      isScrolling: false,
      lastUserInvokedScrollAt: null,
      lastScrollTopPos: 0,
      isAutoScrollPaused: false,
      now: Date.now(),
      nowInterval: null,
      removeSignal: false,
      removeUnclear: true,
      fontSize: 1,
      bypassPausedScroller: false,
    };
  },
  watch: {
    reloadKey: {
      handler(n, o) {
        if (isDefined(n) && (!isDefined(o) || n !== o)) {
          this.$nextTick(() => this.init());
        }
      },
      immediate: true,
    },
    hasSignedUrl(to, from) {
      if (to && !from) {
        this.$nextTick(this.bindRefs);
      }
    },
    shouldAutoScrollPause(n, o) {
      if (n != o && n == true ) {
        this.isAutoScrollPaused = true;
      } else {
        this.isAutoScrollPaused = false;
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getTranscriptions",
      "visibleTranscriptions",
      "breadcrumbHints",
    ]),
    shouldAutoScrollPause() {
      if (this.$refs.controls && !this.$refs.controls.isPlaying) return false;
      // console.log("computed", this.now - this.lastUserInvokedScrollAt, 1000 * 10, this.$refs.transcription && this.$refs.transcription.editingSpeakerAt);
      const withinThreshold = this.now - this.lastUserInvokedScrollAt <= 1000 * 10; // 10 secs
      const isEditing = this.$refs.transcription && this.$refs.transcription.editingSpeakerAt != -1;
      return withinThreshold || isEditing;
    },
    uploadId() {
      const id = this.$route.params.uploadId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    clipId() {
      const id = this.$route.params.clipId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    isClip() {
      return isDefined(this.clipId);
    },
    // Separate out transcript - to avoid unnecessary re-renders
    hasTranscriptModel() {
      return isDefined(this.transcript);
    },
    // Separate out transcript - to avoid unnecessary re-renders
    hasRevision() {
      return isDefined(this.revision);
    },
    transcriptId() {
      return this.hasTranscriptModel ? this.transcript.id : null;
    },
    transcriptSignedUrl() {
      return this.hasTranscriptModel ? this.transcript.signedUrl : null;
    },
    transcriptContent() {
      if (!this.hasTranscriptModel) return null;
      if (this.hasRevision) return this.revision.content;
      return this.transcript.content;
    },
    transcriptContentKey() {
      if (!this.hasTranscriptModel) return null;
      if (this.hasRevision) return this.revision.contentHash;
      return this.transcript.contentHash;
    },
    transcriptMetadata() {
      if (!this.hasTranscriptModel) return null;
      if (this.hasRevision) return this.revision.metadata;
      return this.transcript.metadata;
    },
    transcriptPlayback() {
      return this.hasTranscriptModel ? this.transcript.playback : null;
    },
    transcriptName() {
      return this.hasTranscriptModel ? this.transcript.name : null;
    },
    // End separate out transcript

    hasSignedUrl() {
      return isDefined(this.transcriptSignedUrl);
    },
    codeWordTimeStamps() {
      if (!this.hasTranscript) return [];
      const spans = this.$refs.transcription && this.$refs.transcription.spans;
      if (!spans) return [];
      return this.codeWordIndices.map((cwi) => {
        const matchingSpan = spans.find((s) => s.start <= cwi && cwi <= s.end);
        if (!matchingSpan) return null;
        return matchingSpan;
      }).filter((t) => t !== null);
    },
    reloadKey() {
      if (!isDefined(this.uploadId)) return undefined;
      return this.uploadId + "___" + this.clipId;
    },
    uploadName() {
      return this.breadcrumbHints[ethosRouteNames.ViewUploads];
    },
    transciptionIncidents() {
      // If incident is archived (archive = true), then it doesn't get shown. Only show if archive = false.
      // For some reason, the archive resets each time we reload the page
      return this.incidents.filter((i) => {
        return (
          i.transcriptionId == this.transcriptId &&
          i.archive === false &&
          (!isDefined(this.clip) || (i.end > this.clip.startOffset && i.start < this.clip.endOffset))
        );
      });
    },
    mappedIncidents() {
      if (!this.hasTranscript) return [];
      const spans = this.$refs.transcription && this.$refs.transcription.spans;
      if (!spans) return [];
      return (isDefined(this.clip) ? this.transciptionIncidents : this.incidents).map((i) => {
        if (i.type === "timeline") {
          i.start = i.startTime;
        } else {
          const matchingSpans = spans.filter((s) => s.start < i.endOffset && s.end > i.startOffset);
          let start = -1;
          matchingSpans.forEach((s) => {
            if (start === -1 || s.startOffset < start) start = s.startOffset;
          });
          i.start = start;
        }
        return i;
      });
    },
    transcriptionClass() {
      return `token-font-${this.fontSize}`;
    },
  },
  onDestroy() {
    if (this.updateInterval) window.clearInterval(this.updateInterval);
    if (this.nowInterval) clearInterval(this.nowInterval);
  },
  mounted() {
    this.startNowInterval();
  },
  beforeDestroy() {
    const scrollerEl = document.querySelector(".upload-content");
    if (scrollerEl) {
      scrollerEl.removeEventListener("mousewheel", this.onMouseWheel);
    }
    window.removeEventListener("keyup", this.onKeyUp);
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs.transcription && this.$refs.transcription.isEditMode) {
      next(false);
      this.$notifyWarn("Unsaved content! Please save and try again.");
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations("data", [
      "putTranscription",
    ]),
    init() {
      // not sure why this was here, it checks for transcriptions with an uploadId
      // which is incorrect since getTranscriptions is keyed by transcriptionId.
      // commenting out for now to fix clips not working on fulton instance
      // jk - 6/24/23
      // if (this.hasParentTranscription()) {
      //   this.transcript = this.getTranscriptions[this.uploadId];
      // } else {
      this.loadTranscriptions().then(() => {
        // All Good
        const scrollerEl = document.querySelector(".upload-content");
        if (scrollerEl) {
          scrollerEl.addEventListener("mousewheel", this.onMouseWheel);
        }
        window.addEventListener("keyup", this.onKeyUp);
      }).catch((ex) => {
        this.loading = false;
        this.$notifyError("Audio Transcript request failed", ex);
      });
      //  }
    },
    hasParentTranscription() {
      return (
        Object.keys(this.getTranscriptions).indexOf(`${this.uploadId}`) > -1
      );
    },
    async loadTranscriptions() {
      if (this.loading) return;
      this.loading = true;
      if (isDefined(this.clipId)) {
        this.clip = await getClip(this.uploadId, this.clipId);
        this.$store.commit("data/setCaseClip", this.clip);
      }
      let transcript = await getAudioTranscript(this.uploadId);
      this.putTranscription(transcript); // put the original
      if (isDefined(this.clip)) {
        transcript = this.formatTranscriptForClip(transcript);
      }
      // Store it locally
      await this.applyTranscriptUpdate(transcript);
      this.loading = false;
      this.loadIncidents();
    },
    formatTranscriptForClip(transcript) {
      // If there's a clip - manipulate the transcript metadata
      const clipEntries = transcript.metadata.entries.filter((e) => {
        return (
          this.clip.startOffset < (e.et * 1000) + e.eTm &&
          this.clip.endOffset > (e.st * 1000) + e.sTm
        );
      });
      const first = clipEntries.length > 0 ? clipEntries[0].s : 0;
      const last = clipEntries.length > 1 ? clipEntries[clipEntries.length - 1].e : transcript.content.length;
      transcript.metadata.entries = clipEntries;

      // trim the content - padding blank at start to keep indicies
      transcript.content = transcript.content.slice(first, last).padStart(last);

      // Update the speakers too
      const clipSpeakers = transcript.metadata.speakers.filter((s) => s.s < last);
      let firstSpeaker = 0;
      for (let s = 0; s < clipSpeakers.length; s++) {
        // Get rid of excess speakers
        if (clipSpeakers[s].s <= first) firstSpeaker = s;
      }
      transcript.metadata.speakers = clipSpeakers.slice(firstSpeaker);
      transcript.signedUrl = this.clip.signedUrl;
      return transcript;
    },
    loadIncidents() {
      getUploadIncidents(this.uploadId)
        .then((incidents) => {
          this.incidents = incidents;
        })
        .catch((ex) => {
          this.$notifyError("Failed to load Signal", ex);
        });
    },
    bindRefs() {
      if (this.hasTranscript) return;
      if (!this.$refs.transcription) return;
      this.hasTranscript = true;
    },
    bindTranscriptionClick() {
      this.$refs.transcription.bindClicks();
    },
    seekTo(offset) {
      this.$refs.controls.seekTo(offset);
    },
    updateTime(t) {
      this.currentPlaytime = t;
    },
    setUnclearWordsCount(count) {
      this.unclearWordsCount = count;
    },
    async applyTranscriptUpdate(transcript) {
      this.revision = null;
      if (!isDefined(transcript)) return;

      // Calculate and apply the hash
      const hash = await this.createContentHash(transcript.content);
      transcript.contentHash = hash;

      if (isDefined(this.clip)) {
        transcript = this.formatTranscriptForClip(transcript);
      }

      // Different transcript entirely? apply it
      if (!isDefined(this.transcript) || this.transcript.id !== transcript.id) {
        this.transcript = transcript;
        return;
      }

      // Go by parts
      if (this.transcript.contentHash !== transcript.contentHash) {
        this.transcript.content = transcript.content;
        this.transcript.contentHash = transcript.contentHash;
      }
      if (this.transcript.modifiedOn != transcript.modifiedOn) {
        this.transcript.modifiedOn = transcript.modifiedOn;
        this.transcript.modifiedBy = transcript.modifiedBy;
      }
      if (this.transcript.workflowPersistenceId != transcript.workflowPersistenceId) {
        this.transcript.workflowPersistenceId = transcript.workflowPersistenceId;
      }
      if (this.transcript.parentTranscriptionId != transcript.parentTranscriptionId) {
        this.transcript.parentTranscriptionId = transcript.parentTranscriptionId;
      }
      if (this.transcript.name != transcript.name) {
        this.transcript.name = transcript.name;
      }
      if (this.transcript.language != transcript.language) {
        this.transcript.language = transcript.language;
      }
      if (this.transcript.failureReason != transcript.failureReason) {
        this.transcript.failureReason = transcript.failureReason;
      }

      // Objects/Arrays just compare by stringify for now.
      if (JSON.stringify(this.transcript.upload) != JSON.stringify(transcript.upload)) {
        this.transcript.uploadId = transcript.uploadId;
        this.transcript.upload = transcript.upload;
      }
      if (JSON.stringify(this.transcript.playback) != JSON.stringify(transcript.playback)) {
        this.transcript.playbackId = transcript.playbackId;
        this.transcript.playback = transcript.playback;
      }
      if (JSON.stringify(this.transcript.metadata) != JSON.stringify(transcript.metadata)) {
        this.transcript.metadata = transcript.metadata;
      }
      if (JSON.stringify(this.transcript.vector) != JSON.stringify(transcript.vector)) {
        this.transcript.vector = transcript.vector;
      }
    },
    async transcriptUpdated(transcript) {
      if (!transcript || !this.transcript) return;
      this.putTranscription(transcript);
      await this.applyTranscriptUpdate(transcript);
    },
    async createContentHash(content) {
      if (!isDefined(content)) return "";
      try {
        // hash the content for use as a key, so we're not using the entire string as a comparison for every re-render.
        const hashData = await window.crypto.subtle.digest("SHA-1", new TextEncoder().encode(content));
        return [].map.call(new Uint8Array(hashData), (octet) => octet.toString(16).padStart(2, "0")).join("");
      } catch (ex) {
        return content;
      }
    },
    contentUpdated(newContent) {
      this.createContentHash(newContent).then((hash) => {
        this.transcript.content = newContent;
        this.transcript.contentHash = hash;
      });
    },
    selectSpeaker(options) {
      console.warn(options);
      this.originalSpeaker = isDefined(options.uid) ? options.uid : (isDefined(options.aid) ? options.aid : null);
      this.selectSpeakerCallback = (p) => {
        options.callback(options, p, p.mapAll === true);
      };
      this.$refs.speakerForm.showModal = true;
    },
    speakerChanged(p) {
      if (this.selectSpeakerCallback) {
        this.selectSpeakerCallback(p);
        this.originalSpeaker = null;
        this.selectSpeakerCallback = () => {};
      }
    },
    appendMediaElement(element) {
      const el = element && element.el;
      if (el) {
        if (el.parentNode) el.parentNode.removeChild(el);
        el.controls = false;
        document.body.appendChild(el); // TODO: - Choose container when there's video
      }
    },
    removeMediaElement(element) {
      const el = element && element.el;
      if (el) {
        if (el.parentNode) el.parentNode.removeChild(el);
      }
    },
    updatedIncident(incident) {
      let matching = false;
      for (let i = 0; i < this.incidents.length; i++) {
        if (!matching && this.incidents[i].id === incident.id) {
          this.incidents[i] = Object.assign(this.incidents[i], incident);
          matching = true;
        }
      }
      if (!matching) {
        this.incidents.push(incident);
      }
    },
    updatedClip(clip) {
      if (this.$refs.controls) this.$refs.controls.endClipping();
      console.log("Handle", clip);
    },
    scrollToNext(e) {
      const currentIndex = this.keywordIndices[this.keywordCurrentIndex];
      const element = document.getElementById(currentIndex);
      element.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center",
      });
    },
    maybeScrollView(el) {
      if (this.isScrolling || (this.isAutoScrollPaused && !this.bypassPausedScroller)) return;
      // bypass is used for user initiated nav events such as clicking a signal pin,
      // reset it's state
      this.bypassPausedScroller = false;
      const scrollerEl = document.querySelector(".upload-content");
      try {
        if (scrollerEl) {
          const dim = this.getVisibility(el, scrollerEl);
          if (!dim.isVisible) {
            this.scrollTo(scrollerEl, scrollerEl.scrollTop + dim.nodeTop - scrollerEl.clientHeight * 0.85);
          } else if (dim.nearBottom > 0.93) {
            this.scrollTo(scrollerEl, scrollerEl.scrollTop + scrollerEl.clientHeight * 0.85);
          }
        }
      } catch (ex) {
        // If there's an edit that's removed some nodes, then won't be able to find the change
      }
    },
    scrollTo(el, scrollTo, scrollDuration) {
      this.isScrolling = true;
      const anchorHeightAdjust = 30;
      if (scrollTo > anchorHeightAdjust) {
        scrollTo = scrollTo - anchorHeightAdjust;
      }

      // Set a default for the duration
      if ( typeof scrollDuration !== "number" || scrollDuration < 0 ) {
        scrollDuration = 750;
      }
      const cosParameter = (el.scrollTop - scrollTo) / 2;
      let scrollCount = 0;
      let oldTimestamp = window.performance.now();

      const step = (newTimestamp) => {
        let tsDiff = newTimestamp - oldTimestamp;

        // Performance.now() polyfill loads late so passed-in timestamp is a larger offset
        // on the first go-through than we want so I'm adjusting the difference down here.
        // Regardless, we would rather have a slightly slower animation than a big jump so a good
        // safeguard, even if we're not using the polyfill.
        if (tsDiff > 100) {
          tsDiff = 30;
        }
        scrollCount += Math.PI / (scrollDuration / tsDiff);
        // As soon as we cross over Pi, we're about where we need to be
        if (scrollCount >= Math.PI) {
          this.isScrolling = false;
          return;
        }

        const moveStep = Math.round(scrollTo + cosParameter + cosParameter * Math.cos(scrollCount));
        el.scrollTo(0, moveStep);
        oldTimestamp = newTimestamp;
        window.requestAnimationFrame(step);
      };

      window.requestAnimationFrame(step);
    },
    getVisibility(node, referenceNode) {
      referenceNode = referenceNode || node.parentNode;
      const pos = node.getBoundingClientRect();
      const containerPos = referenceNode.getBoundingClientRect();
      let isVisible = false;
      let nearTop = pos.y <= containerPos.top ? 1 : 0;
      let nearBottom = pos.y >= containerPos.bottom ? 1 : 0;
      const nodeTop = pos.y;
      if (pos.y >= containerPos.top &&
        pos.y <= containerPos.bottom &&
        pos.x >= containerPos.left &&
        pos.y <= containerPos.right)
      // eslint-disable-next-line
      {
        isVisible = true;
        nearTop = 1 - Math.abs(pos.y - containerPos.top)/containerPos.height;
        nearBottom = 1 - Math.abs(pos.y - containerPos.bottom)/containerPos.height;
      }
      return {
        "nearTop": nearTop,
        "nearBottom": nearBottom,
        "isVisible": isVisible,
        "nodeTop": nodeTop,
      };
    },
    onKeyUp(e) {
      if (e.key == "ArrowUp" || e.key == "ArrowDown" || e.key == "PageUp" || e.key == "PageDown") {
        const scrollerEl = document.querySelector(".upload-content");
        if (this.isPlaying() && scrollerEl != null && scrollerEl.scrollTop != this.lastScrollTopPos) {
          this.lastUserInvokedScrollAt = Date.now();
        }
        this.lastScrollTopPos = scrollerEl.scrollTop;
      }
    },
    onMouseWheel(e) {
      if (this.isPlaying()) {
        this.lastUserInvokedScrollAt = Date.now();
      }
    },
    startNowInterval() {
      if (!this.nowInterval) {
        this.nowInterval = setInterval(this.updateNow.bind(this), 1000);
      }
    },
    updateNow() {
      this.now = Date.now();
    },
    resumeAutoScroll() {
      this.lastUserInvokedScrollAt = null;
      if (this.$refs.transcription && this.$refs.transcription.editingSpeakerAt != -1) {
        this.$notifyWarn("To resume auto scroll, please exit editing mode.");
      }
    },
    isPlaying() {
      return this.$refs.controls && this.$refs.controls.isPlaying;
    },
    onRemoveSignal(e) {
      this.removeSignal = !this.removeSignal;
    },
    onRemoveUnclear(e) {
      this.removeUnclear = !this.removeUnclear;
    },
    editMetadata(e) {
      this.$refs.transcription && this.$refs.transcription.editMetadata(e);
    },
    displayRevision(revisedTranscript) {
      if (isDefined(revisedTranscript) && isDefined(revisedTranscript.transcript)) {
        revisedTranscript.transcript.contentHash = revisedTranscript.revisionId;
        this.revision = revisedTranscript.transcript;
      } else {
        this.revision = null;
      }
    },
  },
  beforeDestroy() {
    // reseting the case clip
    this.$store.commit("data/setCaseClip", {});
  },
};
</script>

<style lang="scss">
  .edit-toolbar {
    position: sticky;
    width: 100%;
    z-index: 1000;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
</style>
<style lang="scss">
.token-font-1 {
   font-size: 0.875rem;
}
.token-font-2 {
   font-size: 1rem;

}
.token-font-3 {
   font-size: 1.25rem;
}
.token-font-4 {
   font-size: 1.5rem;
}
.token-font-5 {
   font-size: 1.75rem;
}
.token-font-6 {
   font-size: 2rem;
}
.token-font-7 {
   font-size: 2.25rem;

}
.token-font-8 {
   font-size: 2.5rem;
}
.token-font-9 {
   font-size: 2.755rem;
}
.token-font-10 {
   font-size: 3rem;
}
</style>
