<template>
  <div
    class="upload-content flex-grow-1 pt-4"
  >
    <div
      ref="parentDiv"
      class="tokenized-transcription-content"
      @click.right="handleRightClick"
      @mousedown="handleMouseDown"
      @mouseup="maybeShowRightClickMenu"
    >
      <template v-for="(c, index) in speakers">
        <div
          :key="`${c.speakerPos}-${index}`"
          class="d-flex align-items-baseline spk-container"
        >
          <el-tooltip
            v-if="showUploadsPopover && speakerChangeRequests[c.speakerPos]"
            :key="`${c.speakerPos}-${metadata.requestedChanges.length}`"
            transition=""
          >
            <transcription-speaker
              :ref="`spk-${c.speakerPos}`"
              :data-spk="c.speakerPos"
              :detail="c"
              :disabled="disabled"
              @select="selectSpeaker"
              @remove="removeSpeaker"
            />
            <template slot="content">
              <change-requests

                :scrs="speakerChangeRequests[c.speakerPos]"
                :actor-map="actorMap"
                :user-map="userMap"
                @approve="approve"
                @reject="reject"
                @undo="undoChangeRequest"
              />
            </template>
          </el-tooltip>
          <transcription-speaker
            v-else
            :ref="`spk-${c.speakerPos}`"
            :data-spk="c.speakerPos"
            :detail="c"
            :disabled="disabled"
            @select="selectSpeaker"
            @remove="removeSpeaker"
          />
          <div
            v-if="c.content.length > 0"
            class="ml-auto speaker-time-series"
            contenteditable="false"
          >
            {{ formatTime(c.content[0].startOffset) }}
          </div>
        </div>
        <div
          :key="'c-' + c.speakerPos + '-' + index"
          class="mt-2 mb-4 span-container"
          :class="{
            'highlight-edit-section': isEditMode,
            'has-span': (
              c.content && c.content.length > 0 &&
              c.content[0].startOffset <= currentPlaytimeWithOffset &&
              c.content[c.content.length -1].endOffset >= currentPlaytimeWithOffset &&
              (!hasClip || (hasClip && clip.startOffset / 1000 != currentPlaytimeWithOffset))
            ),
          }"
          :data-i="c.speakerPos"
          :contenteditable="isEditMode"
        >
          <template v-for="(span, spanIx) in c.content">
            <template
              v-if="showUploadsPopover &&
                editingSpeakerAt != c.speakerPos &&
                (speakerChangeRequests[span.start] || contentChangeRequests[span.start])
              "
            >
              <el-tooltip

                :key="`${span.start}-${span.end}-${span.content}-${metadata.requestedChanges.length}-${spanIx}`"
                transition=""
              >
                <change-requests

                  slot="content"
                  :scrs="speakerChangeRequests[span.start]"
                  :crs="contentChangeRequests[span.start]"
                  :actor-map="actorMap"
                  :user-map="userMap"
                  @approve="approve"
                  @reject="reject"
                  @undo="undoChangeRequest"
                />
                <transcription-span
                  :ref="`trSpan-${span.start}-${span.end}`"
                  :span="span"
                  :speaker-pos="c.speakerPos"
                  :editing-speaker-at="editingSpeakerAt"
                  :incidents="incidents"
                  :unclear-threshold="unclearThreshold"
                  :current-playtime="currentPlaytimeWithOffset"
                  :keyword-indices="keywordIndices"
                  :keyword-current-index="keywordCurrentIndex"
                  :code-word-indices="codeWordIndices"
                  :audio-ready="activeSpanClicks"
                  :clip-range="clipRange"
                  :is-editing="isEditMode"
                  :signal-value="signalValue"
                  :unclear-value="unclearValue"
                  has-change-request
                  @seek="onSeek"
                  @index="(ind) =>$emit('index', ind)"
                  @incident="editIncident"
                  @nextSpeaker="goToNextSpeaker"
                  @prevSpeaker="goToPrevSpeaker"
                  @insertSpeaker="insertSpeakerWithStart"
                  @endEditing="cancelEdits"
                />
              </el-tooltip>
            </template>

            <!-- eslint-disable-next-line vue/valid-v-for -->
            <transcription-span
              v-else
              :ref="`trSpan-${span.start}-${span.end}`"
              :span="span"
              :speaker-pos="c.speakerPos"
              :editing-speaker-at="editingSpeakerAt"
              :incidents="incidents"
              :unclear-threshold="unclearThreshold"
              :current-playtime="currentPlaytimeWithOffset"
              :keyword-indices="keywordIndices"
              :keyword-current-index="keywordCurrentIndex"
              :code-word-indices="codeWordIndices"
              :audio-ready="activeSpanClicks"
              :clip-range="clipRange"
              :is-editing="isEditMode"
              :signal-value="signalValue"
              :unclear-value="unclearValue"
              @seek="onSeek"
              @index="(ind) =>$emit('index', ind)"
              @incident="editIncident"
              @nextSpeaker="goToNextSpeaker"
              @prevSpeaker="goToPrevSpeaker"
              @insertSpeaker="insertSpeakerWithStart"
              @endEditing="cancelEdits"
              @isPlaying="(el) => $emit('spanPlaying', el)"
            />
            <!--
              - Make custom Signals be attributable to a User/Actor
              -->
          </template>
        </div>
      </template>
      <ul
        v-if="rightClickMenuVisible"
        id="dmenu"
        ref="dmenu"
        class="el-dropdown-menu el-popper"
        :style="{ top: menuY + 'px', left: menuX + 'px', display: 'block' }"
      >
        <li
          v-if="highlightedRange !== null"
          tabindex="0"
          class="el-dropdown-menu__item"
          @click="quickAssignSpeaker"
        >
          Quick Assign Speaker
        </li>
        <li
          tabindex="1"
          class="el-dropdown-menu__item divided"
          @click="assignToSpeaker"
        >
          Change Speaker
        </li>
        <li
          v-if="!isEditMode"
          tabindex="2"
          class="el-dropdown-menu__item"
          @click="menuItemEdit"
        >
          Edit
        </li>

        <li
          v-if="focusedNodes.length === 1 && focusedNodes[0].className.indexOf('unclear') > -1"
          tabindex="3"
          class="el-dropdown-menu__item"
          @click="markClear"
        >
          Mark Word Correct
        </li>

        <li
          v-if="focusedNodes.length === 1 && focusedNodes[0].className.indexOf('redacted') > -1"
          tabindex="3"
          class="el-dropdown-menu__item"
          @click="unRedactContent"
        >
          Remove Redaction
        </li>

        <li
          v-if="highlightedRange !== null ||
            (rightClickTarget &&
              isSpan(rightClickTarget) &&
              rightClickTarget.className.indexOf('span-container') === -1)"
          tabindex="4"
          class="el-dropdown-menu__item"
          @click="redactContent"
        >
          Redact Content
        </li>
        <li
          v-if="focusedNodes.length > 0 &&
            focusedNodes.filter((f) => f.className.indexOf('incident') === -1).length === 0"
          tabindex="4"
          class="el-dropdown-menu__item"
          @click="viewIncident"
        >
          View Clip
        </li>

        <li
          v-if="highlightedRange !== null && !hasClip"
          tabindex="4"
          class="el-dropdown-menu__item"
          @click="createIncident"
        >
          {{ displayIncidents ? 'Save Selection' : 'Create Clip' }}
        </li>

        <li
          v-if="isSpanRightClicked == true"
          tabindex="5"
          class="el-dropdown-menu__item"
          @click="insertSpeaker"
        >
          Insert Speaker Before
        </li>
        <li
          v-if="selection !== null"
          tabindex="6"
          class="el-dropdown-menu__item"
          @click="() => copyHighlightedText(true)"
        >
          Copy
        </li>
      </ul>
      <incident-form-modal
        ref="incidentForm"
        @created="createdIncident"
        @updated="updatedIncident"
        @close="closeMenu"
      />
      <add-people-modal
        ref="assignToSpeakerForm"
        :case-id="caseId"
        :upload-id="uploadId"
        associate
        @associated="assignContentToSpeaker"
        @created="assignContentToSpeaker"
        @close="closeAssignSpeakerForm"
      />
      <clip-form-modal
        ref="clipForm"
        :upload-id="uploadId"
        @clip="(c) => $emit('updatedClip', c)"
        @close="handleClipFormClose"
      />
    </div>
  </div>
</template>

<script>
import {postChangeRequests, postChangeRequestApproval, postMarkClear, postSpeakerChangeRequest, postRemoveRedaction, postRedaction, addActor, attributeActor} from "../../../../api";
import {mapActions, mapGetters, mapMutations} from "vuex";
import TranscriptionSpeaker from "./TranscriptionSpeaker.vue";
import ChangeRequests from "./ChangeRequests.vue";
import IncidentFormModal from "../IncidentFormModal.vue";
import AddPeopleModal from "../../../../components/DashboardV2/Case/AddPeopleModal.vue";
import ClipFormModal from "../ClipFormModal.vue";
import {isDefined} from "../../../../api/helpers";
import TranscriptionSpan from "./TranscriptionSpan.vue";
import {IncidentType} from "../../../../util/enums";
import {ethosRouteNames} from "../../../../routes/routeNames";
import {convertTranscriptDomToText, transcriptionMetadataToSpans, transcriptionMetadataToSpeakers} from "../../../../util/exportUtil";

export default {
  components: {
    ChangeRequests,
    TranscriptionSpeaker,
    ClipFormModal,
    IncidentFormModal,
    AddPeopleModal,
    TranscriptionSpan,
  },
  name: "TokenizedTranscriptionContent",
  props: {
    content: {
      type: String,
      default: null,
    },
    metadata: {
      type: Object,
      default: null,
    },
    uploadId: {
      type: Number,
      default: null,
    },
    transcriptionId: {
      type: Number,
      default: 0,
    },
    transcriptionName: {
      type: String,
      default: "Transcript",
    },
    incidents: {
      type: Array,
      default() {
        return [];
      },
    },
    currentPlaytime: {
      type: Number,
      default: 0,
    },
    keywordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    keywordCurrentIndex: {
      type: Number,
      default: -1,
    },
    codeWordIndices: {
      type: Array,
      default() {
        return [];
      },
    },
    clip: {
      type: Object,
      default: null,
    },
    signalValue: {
      type: Boolean,
      default: true,
    },
    unclearValue: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    highlightedRange: function(n, o) {
      if (n == null && n != o) {
        const isFormOpen = this.$refs.assignToSpeakerForm.isDisplayed() ||
          this.$refs.clipForm.isDisplayed ||
          this.$refs.incidentForm.isDisplayed;
        if (isFormOpen) {
          this.highlightedRange = o;
        } else {
          this.highlightOnContentRange(o.nodes.start, o.nodes.end, "selected", true);
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", [
      "userEmail",
    ]),
    ...mapGetters("data", [
      "getUploads",
      "getActorMap",
      "getUserMap",
      "displayIncidents",
      "showUploadsPopover",
      "highlightSnippet",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    hasClip() {
      return isDefined(this.clip);
    },
    currentPlaytimeWithOffset() {
      const clipOffset = this.hasClip ? this.clip.startOffset / 1000 : 0;
      return this.currentPlaytime + clipOffset;
    },
    contentChangeRequests() {
      if (!this.metadata || !this.metadata.requestedChanges) return [];
      const t = this.metadata.requestedChanges
        .filter((rc) => rc.type == "E")
        .reduce((all, rc) => {
          if (!all[rc.s]) all[rc.s] = [];
          all[rc.s].unshift(rc); // newest first
          return all;
        }, {});
      return t;
    },
    speakerChangeRequests() {
      if (!this.metadata || !this.metadata.requestedChanges) return [];
      const combined = {};
      this.metadata.requestedChanges
        .filter((rc) => rc.type == "S")
        .reduce((all, rc) => {
          if (!all[rc.s]) all[rc.s] = [];
          all[rc.s].unshift(rc); // newest first
          return all;
        }, combined);
      return combined;
    },
    speakers() {
      return this.getSpeakers(this.metadata, this.content, this.spans);
    },
    spans() {
      return this.getSpans(this.metadata, this.content);
    },
    userMap() {
      return this.getUserMap;
    },
    actorMap() {
      return this.getActorMap;
    },
  },
  data() {
    return {
      activeSpanClicks: false,
      currentHighlight: {
        line: -1,
        index: -1,
      },
      clipRange: null,
      tslookup: [],
      editingSpeakerAt: -1,
      unclearThreshold: 75,
      transcriptIndex: null,
      focusedSection: -1,
      focusedNodes: [],
      highlightedRange: null,
      cases: [],
      isEditMode: false,
      editModeTransitionEnded: {},
      editObserver: null,
      isSpanRightClicked: false,
      rightClickTarget: null,
      removedSpans: [],
      loading: true,
      assignSpeakerEventData: null,
      rightClickMenuVisible: false,
      menuX: 0,
      menuY: 0,
      selection: null,
      blockSelectionUpdate: false,
    };
  },
  mounted() {
    if (
      Object.keys(this.getUserMap).length +
      Object.keys(this.getActorMap).length === 0 ) {
      this.doLoadPeople(true);
    } else {
      this.loading = false;
    }
    document.addEventListener("copy", this.copyEventListener);
    document.addEventListener("selectionchange", this.selectionChangeListener);
    window.setTimeout(() => {
      try {
        if (this.highlightSnippet) {
          const index = this.content.indexOf(this.highlightSnippet);
          if (index > -1) {
            const next = this.spans.findIndex((s) => s.start > index);
            if (next < 1) next = 0;
            const start = this.spans[next -1];
            const el = document.querySelector(`span[data-key="${start.start}-${start.end}"]`);
            if (el) el.scrollIntoView();
          }
          this.putHighlightSnippet(null);
        }
      } catch (ex) {
        // ignore
      }
    }, 50);
  },
  beforeDestroy() {
    this.stopObserving();
    document.removeEventListener("copy", this.copyEventListener);
    document.removeEventListener("selectionchange", this.selectionChangeListener);
    document.removeEventListener("click", this.clickListener);
  },
  methods: {
    ...mapActions("data", ["loadPeople", "loadActors", "loadUsers"]),
    ...mapMutations("data", ["putHighlightSnippet"]),
    beforeUnloadHandler(event) {
      event.preventDefault();
      // Included for legacy support, e.g. Chrome/Edge < 119
      event.returnValue = true;
    },
    startObserving() {
      if (this.observer) {
        return false;
      }
      const config = {characterData: true, childList: true, subtree: true};

      this.observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === "characterData") {
            const key = mutation.target.parentElement.attributes["data-key"].value;
            // trigger onInput of span
            this.$refs[`trSpan-${key}`][0].onInput(mutation.target.data);
          }
          if (mutation.type === "childList") {
            // deleted words will be tracked here so that when save is called
            // we can apply those deletions (replace) to the content
            mutation.removedNodes.forEach((node) => {
              // check if node is a space to determine that it is a "replace"
              // and shouldn't be added back
              // TODO: look into a better way of detecting "replace"'d nodes
              if (node.dataset.o === " ") return;
              const clone = node.cloneNode(true);
              clone.style.visibility = "hidden";
              this.removedSpans.push(clone);
            });
          }
        });
      });

      this.$refs.parentDiv.querySelectorAll(".span-container").forEach((node) => {
        if (!this.observer.takeRecords().length) {
          this.observer.observe(node, config);
        }
      });
      window.addEventListener("beforeunload", this.beforeUnloadHandler);
      return true;
    },
    stopObserving() {
      if (this.observer) {
        this.observer.disconnect();
        this.observer = null;
      }
      window.removeEventListener("beforeunload", this.beforeUnloadHandler);
    },
    getSpans(metadata, content) {
      return transcriptionMetadataToSpans(metadata, content);
    },
    getSpeakers(metadata, content, spans) {
      if (this.loading) return [];
      return transcriptionMetadataToSpeakers(metadata, content, spans);
    },
    doLoadPeople(force) {
      if (this.loading) {
        if (force !== true) return;
      }
      this.loading = true;
      this.loadPeople().catch((ex) => {
        this.$notifyError("Loading personel info failed", ex);
      }).finally(() => {
        this.loading = false;
      });
    },
    highlightWordBefore(currentTime) {
      for (let i = this.tslookup.length - 1; i >= 0; i--) {
        if (this.tslookup[i].start <= currentTime) {
          this.currentHighlight.line = this.tslookup[i].line;
          this.currentHighlight.index = this.tslookup[i].index;
          return;
        }
      }
    },
    bindClicks() {
      this.activeSpanClicks = true;
    },
    handleClipFormClose() {
      this.clipRange = null;
      this.closeMenu();
    },
    handleRightClick(e) {
      if (this.disabled) return;
      e.preventDefault();
      this.blockSelectionUpdate = true;
      if (e && e.target) {
        this.rightClickTarget = e.target;
        // const isSpan = e.target.nodeName.toUpperCase() === "SPAN";
        // if (isSpan) this.isSpanRightClicked = true;
        this.showRightClickMenu(e);
      }
    },
    handleMouseDown(e) {
      if (this.disabled) return;
      if (e.button === 2) {
        // Anticipate a right-click, so set the flag to block updating the selection
        this.blockSelectionUpdate = true;
      } else {
        // For other mouse buttons, ensure normal behavior
        this.blockSelectionUpdate = false;
      }
    },
    clickListener(e) {
      if (this.rightClickMenuVisible && this.$refs.dmenu && !this.$refs.dmenu.contains(e.target)) {
        this.closeMenu();
      }
    },
    selectionChangeListener(e) {
      if (!this.blockSelectionUpdate && !this.disabled) {
        setTimeout(() => {
          const s = window.getSelection();
          this.selection = s.rangeCount > 0 && !s.isCollapsed ? s.getRangeAt(0) : null;
          // console.log("selectiongChangeListner TIMEOUT", this.selection.startContainer, this.selection.endContainer);
          if (this.selection &&
          this.selection.nodeType === Node.TEXT_NODE &&
          this.selection.startContainer === this.selection.endContainer) {
            // if selecting a single word, we expand the selection to include dom element
            const parentElement = this.selection.startContainer.parentNode;

            // Create a new range that includes the parent element
            const newRange = document.createRange();
            this.selection.selectNode(parentElement);

            // Clear the existing selection and add the new range
            // selection.removeAllRanges();
            // selection.addRange(range);
            this.selection = newRange;
          }
        }, 350);
      }
    },
    maybeShowRightClickMenu(e) {
      e.preventDefault();
      if (this.selection != null && this.highlightedRange === null) {
        this.showRightClickMenu(e);
      } else {
        // clear selection if RMC wasn't shown, like a double click scenario
        const s = window.getSelection();
        if (!s.isCollapsed) {
          s.removeAllRanges();
        }
      }
    },
    findWord(selectedNode, dir) {
      const validDirections = ["next", "previous"];
      dir = validDirections.includes(dir) ? dir : "next";
      let node = null;
      node = selectedNode.nodeType === Node.TEXT_NODE ? selectedNode.parentNode : selectedNode;
      let n = selectedNode;
      let isSpeaker = false;
      let isContent = false;
      while (n != null) {
        isSpeaker = n.parentNode.className?.indexOf("spk-container") > -1;
        isContent = n.parentNode.className?.indexOf("span-container") > -1;
        // break when parent node is span container, means we found content
        if (isContent) break;
        // break when parent node is speaker container, means we found a speaker
        if (isSpeaker) {
          // set to speaker container so that findWord can get the next/prev content
          n = n.parentNode;
          break;
        }
        n = n.parentNode;
      }
      node = n;
      if (!this.isSpan(node) || node.className.indexOf("span-container") > -1) {
        let n = dir === "next" ? node.nextSibling : node.previousSibling;
        while (n != null && n.className?.indexOf("span-container") === -1) {
          n = dir === "next" ? n.nextSibling : n.previousSibling;
        }
        return dir === "next" ? n?.firstChild : n?.lastChild;
      } else {
        return node;
      }
    },
    showRightClickMenu(e) {
      if (this.disabled) return;
      if (this.editingSpeakerAt >= 0) return;
      e.stopPropagation();
      e.preventDefault();
      try {
        this.focusedSection = parseInt(e.currentTarget.dataset["i"], 10);
      } catch (ex) {
        console.log("Unable to find section index");
      }
      this.focusedNodes = [e.target];
      const x = e.clientX;
      const y = e.clientY;
      try {
        if (e.target.contentEditable !== "true") {
          const range = this.selection;
          if (range && range.toString().trim().length > 0) {
            const anchorNode = this.findWord(range.startContainer, "next");
            const focusNode = this.findWord(range.endContainer, "previous");

            // console.log("show RMC", anchorNode, focusNode);
            if (anchorNode && focusNode) {
              this.highlightOnContentRange(anchorNode, focusNode, "selected");
              const as = parseInt(anchorNode.getAttribute("data-s"), 10);
              const ae = parseInt(anchorNode.getAttribute("data-e"), 10);
              const fs = parseInt(focusNode.getAttribute("data-s"), 10);
              const fe = parseInt(focusNode.getAttribute("data-e"), 10);
              const start = Math.min(as, fs);
              const end = Math.max(ae, fe);
              if (isDefined(start) && isDefined(end)) {
                this.highlightedRange = {
                  start,
                  end,
                  text: this.content.slice(start, end),
                  type: IncidentType.transcript,
                  transcriptId: this.transcriptionId,
                  uploadId: this.uploadId,
                  nodes: {
                    start: as === start ? anchorNode : focusNode,
                    end: as === start ? focusNode : anchorNode,
                  },
                };
              }
            }
          }
        }
      } catch (ex) {
        console.error("Error showing right click menu", ex);
      }

      this.rightClickMenuVisible = true;
      // add an event listener after right click menu is shown,
      // if we add too early, the listener will catch the click
      // that is still being processed to show the RMC in this
      // context
      document.removeEventListener("click", this.clickListener);
      setTimeout(() => {
        document.addEventListener("click", this.clickListener);
      }, 100);

      this.$nextTick(() => {
        const menuRect = this.$refs.dmenu.getBoundingClientRect();
        const vpW = window.innerWidth;
        const vpH = window.innerHeight;
        this.menuX = x + menuRect.width > vpW ? x - menuRect.width : x;
        this.menuY = y + menuRect.height > vpH ? y - menuRect.height : y;
      });
    },
    onSeek(endTime) {
      this.clearHighlights();
      this.$emit("seek", endTime);
    },
    clearHighlights() {
      document.querySelectorAll("div.span-container span").forEach((el) => {
        try {
          const key = el.attributes["data-key"].value;
          const ref = this.$refs[`trSpan-${key}`][0];
          if (ref.isHighlighted) ref.isHighlighted = false;
        } catch (ex) {}
      });
    },
    isSpan(node) {
      return node.nodeName === "SPAN" || node.tagName === "SPAN";
    },
    // highlights content 1 way across next node siblings
    highlightOnContentRange(startNode, endNode, className, doRemove) {
      // this.clearHighlights();
      let sibling = null;
      sibling = startNode;
      while (sibling && (sibling.attributes["data-s"]?.value | 0) < (endNode.attributes["data-e"]?.value | 0)) {
        const key = sibling.attributes["data-key"].value;
        if (doRemove) {
          this.$refs[`trSpan-${key}`][0].isHighlighted = false;
        } else {
          if (sibling.className.indexOf("span-container") === -1) {
            this.$refs[`trSpan-${key}`][0].isHighlighted = true;
          }
        }

        if (sibling.nextSibling) {
          sibling = sibling.nextSibling;
        } else {
          sibling = sibling.parentNode.nextSibling;

          while (sibling != null && sibling.className != null &&
            // check for span containers which should be text, but there may be cases of empty
            // span containers so we need to check for that as well
            (sibling.className.indexOf("span-container") === -1 || sibling.firstChild == null )) {
            sibling = sibling.nextSibling;
          }
          sibling = sibling?.firstChild;
        }
      }
    },
    replaceSection(speakerPos) {
      let first = true;
      document.querySelectorAll(`div.span-container[data-i="${speakerPos}"] span`).forEach((el) => {
        if (first) {
          first = false;
          el.focus();
        } else {
          el.style.visibility = "hidden";
        }
      });
    },
    saveEdits() {
      if (this.disabled) return;
      this.closeMenu();
      this.doSaveEdits().then((t) => {
        this.$emit("updated", t);
        this.cancelEdits();
        if (t) {
          this.$notifySuccess("Changes Requested");
        }
      }).catch((ex) => {
        this.$notifyError("Unable to Request Changes", ex);
      }); // Its a replace
    },
    async doSaveEdits() {
      // Nodes detected as removed from the observer are then added back as hidden.
      // The hidden nodes should be treated as "replaced".
      // Changed nodes should be treated as "formatted".

      const container = document.querySelector(".tokenized-transcription-content");
      const hiddenGroups = this.findAdjacentHiddenSpans(this.removedSpans);
      this.removedSpans = [];
      const changes = [];
      if (hiddenGroups.length > 0) {
        changes.push(...this.prepareContentReplaceChanges(hiddenGroups));
      }
      for (const hg of hiddenGroups) {
        for (const sp of hg) {
          // remove the data-d attribute which indicates a change was made,
          // since this node was removed/hidden, it shouldn't be considered
          // as a "formatted" change.
          sp.removeAttribute("data-d");
        };
      }

      const changedElements = container.querySelectorAll("span[data-d]");
      const formattedChanges = [...changedElements].map((c) => this.formatChange(c));
      changes.push(...formattedChanges);

      const assignSpeakers = container.querySelectorAll("span[data-new-speaker-p]");
      const pairs = {};
      assignSpeakers.forEach((el) => {
        const p = el.getAttribute("data-new-speaker-p");
        const isStart = el.getAttribute("data-new-speaker-s");
        const isEnd = el.getAttribute("data-new-speaker-e");
        const speakerId = isStart || isEnd;
        if (speakerId) {
          if (!pairs[p]) pairs[p] = {speakerId};
          if (isStart) {
            pairs[p].start = parseInt(el.getAttribute("data-s"), 10);
          }
          if (isEnd) {
            pairs[p].end = Math.max(1, parseInt(el.getAttribute("data-e"), 10) - 1);
          }
        }
      });

      let newContent = null;
      const validPairs = Object.values(pairs).filter((p) => p.end && p.start);
      for (let i = 0; i < validPairs.length; i++) {
        const userId = validPairs[i].speakerId.split("|u:").pop();
        const actorId = validPairs[i].speakerId.split("|u:")[0].split(":").pop();
        newContent = await postSpeakerChangeRequest(
          this.transcriptionId,
          validPairs[i].start,
          validPairs[i].end,
          null,
          false,
          true,
          null,
          userId.length > 0 ? userId : null,
          actorId.length > 0 ? actorId : null
        );
      }
      if (changes.length > 0) {
        newContent = await this.contentChange(changes);
      }

      for (const hg of hiddenGroups) {
        for (const sp of hg) {
          sp.style.visibility = "";
        };
      }
      return newContent;
    },
    findAdjacentHiddenSpans(spans) {
      const hiddenGroups = [];
      let currentGroup = [];
      let previousEndIndex = -1;

      spans.forEach((span, i) => {
        const startIdx = parseInt(span.getAttribute("data-s"), 10);
        const endIdx = parseInt(span.getAttribute("data-e"), 10);

        if (span.style.visibility === "hidden") {
          // If the start index is equal to the previous end index, it's part of the same group
          if (startIdx === previousEndIndex) {
            currentGroup.push(span);
          } else {
            // Otherwise, push the current group to hiddenGroups and start a new one
            if (currentGroup.length > 0) {
              hiddenGroups.push(currentGroup);
            }
            currentGroup = [span];
          }
          previousEndIndex = endIdx;
        } else if (currentGroup.length > 0) {
          // If the span is not hidden and there is a current group, push the group to the result and start a new group
          hiddenGroups.push(currentGroup);
          currentGroup = [];
          previousEndIndex = -1; // Reset the previous end index since the current span is not hidden
        }
        // If we're on the last span and there's a current group, push the group to the result
        if (i === spans.length - 1 && currentGroup.length > 0) {
          hiddenGroups.push(currentGroup);
        }
      });

      return hiddenGroups; // Returns an array of arrays, where each sub-array is a group of adjacent hidden spans
    },
    cancelEdits() {
      this.closeMenu();
      this.isEditMode = false;
      this.stopObserving();
      this.editingSpeakerAt = -1;
    },
    insertSpeaker() {
      if (this.focusedNodes.length > 0) {
        const start = parseInt(this.focusedNodes[0].dataset["s"], 10);
        this.insertSpeakerWithStart(start);
      }
    },
    insertSpeakerWithStart(start) {
      if (this.disabled) return;
      const me = this;
      postSpeakerChangeRequest(
        this.transcriptionId, start, null, null, false, true, null, null, null
      ).then((t) => {
        me.$emit("updated", t);
        me.$notifySuccess("Successfully requested to add Speaker");
        me.$nextTick(() => {
          if (me.editingSpeakerAt !== -1) me.goToNextSpeaker();
        });
      }).catch((ex) => {
        this.$notifyError("Unable to request new Speaker", ex);
      });
    },
    async quickAssignSpeaker(e) {
      if (this.highlightedRange == null) return;
      const unknownSpeakerName = "Unknown Speaker";
      const ix = Object.keys(this.actorMap).find((x) => this.actorMap[x].fullName === unknownSpeakerName);
      let quickAssignActor = this.actorMap[ix];
      let quickAssignActorName = unknownSpeakerName;
      try {
        const prevSpeaker = this.highlightedRange.nodes.start.parentNode.previousSibling;
        const speakerStart = prevSpeaker.childNodes[0].dataset.spk;
        const existingSpeaker = this.speakers.filter((s) => `${s.speakerPos}` === speakerStart).pop();
        if (existingSpeaker && `${ix}` === `${existingSpeaker.speakerActorId}`) {
          quickAssignActor = null;
          quickAssignActorName += " 2";
        }
      } catch (ex) {
        // Ignore error
      }

      if (!isDefined(quickAssignActor)) {
        quickAssignActor = await addActor(quickAssignActorName, null)
          .catch((ex) => {
            this.loading = false;
            this.$notifyError("Failed to create Unknown Speaker actor.", ex);
          });

        await attributeActor(
          quickAssignActor.id,
          "-",
          this.caseId,
          this.uploadId,
          null
        ).catch((ex) => {
          this.$notifyError("Failed to assign Subject", ex);
          return;
        });

        await this.loadActors();
      }
      this.assignSpeakerEventData = Object.assign({}, this.highlightedRange);
      this.doAssignToSpeaker(quickAssignActor.id, null);
    },
    doAssignToSpeaker(actorId, userId) {
      if (!(isDefined(userId) || isDefined(actorId))) return;
      if (this.isEditMode) {
        const now = Date.now();
        const user = `a:${isDefined(actorId) ? actorId : ""}|u:${isDefined(userId) ? userId : ""}`;
        this.highlightedRange.nodes.start.setAttribute("data-new-speaker-s", user);
        this.highlightedRange.nodes.end.setAttribute("data-new-speaker-e", user);
        this.highlightedRange.nodes.start.setAttribute("data-new-speaker-p", now);
        this.highlightedRange.nodes.end.setAttribute("data-new-speaker-p", now);
        this.closeMenu();
      } else {
        const eventData = this.assignSpeakerEventData;
        if (!isDefined(eventData)) return;
        postSpeakerChangeRequest(
          this.transcriptionId,
          eventData.start,
          Math.max(1, parseInt(eventData.end, 10) - 1),
          null,
          false,
          true,
          null,
          userId,
          actorId
        ).then((t) => {
          this.assignSpeakerEventData = null;
          this.$emit("updated", t);
          this.$notifySuccess("Successfully requested to add Speaker");
        }).catch((ex) => {
          this.$notifyError("Unable to request new Speaker", ex);
        }).finally(() => {
          this.closeMenu();
        });
      }
    },
    assignContentToSpeaker(person) {
      if (this.disabled) return;
      const userId = isDefined(person) ? person.userId : null;
      const actorId = isDefined(person) ? person.actorId : null;
      this.doAssignToSpeaker(actorId, userId);
    },
    // onContextMenuSaveClip() {
    //   if (this.highlightedRange == null) {
    //     this.$notifyError("You must first select the text to clip.");
    //     return;
    //   }
    //   const clip = {
    //     in: this.highlightedRange.nodes.start.dataset.so,
    //     out: this.highlightedRange.nodes.end.dataset.eo,
    //   };
    //   this.saveClip(clip);
    // },
    // saveClip(details) {
    //   console.log("Saving in", details.in, "out", details.out);
    //   this.$refs.clipForm && this.$refs.clipForm.display(details.in * 1000, details.out * 1000);
    //   const range = this.highlightedRange;
    //   this.$nextTick(() => {
    //     this.highlightedRange = range;
    //   });
    // },
    menuItemEdit(directionHint) {
      if (this.disabled) return;
      this.editingSpeakerAt = this.focusedSection;
      const highlighting = this.highlightedRange && this.highlightedRange.nodes;
      this.closeMenu();
      const containers = this.$refs.parentDiv.querySelectorAll(".span-container");
      containers.forEach((node) => {
        // transitionend event only firing of background-color with current styling
        const state = {
          "background-color": false,
        };
        this.editModeTransitionEnded[`i-${node.dataset.i}`] = state;
      });

      // listen for transitionend event on all span-containers and then start observing
      // this ensures we're not observing while the transition is happening
      containers.forEach((node) => {
        node.addEventListener("transitionend", (e) => {
          this.editModeTransitionEnded[`i-${node.dataset.i}`][e.propertyName] = true;
          if (this.checkAllPropertiesTrue(this.editModeTransitionEnded)) {
            if (this.startObserving()) {
              if (this.focusedNodes.length > 0) {
                const newNodes = this.focusedNodes.map((fn) => {
                  if (fn.id) return document.getElementById(fn.id);
                  return null;
                }).filter((nn) => nn);
                this.focusedNodes = newNodes;
                const start = highlighting ? document.getElementById(highlighting.start.id) : null;
                const end = highlighting ? document.getElementById(highlighting.start.id) : null;
                if (start && end) {
                  end.scrollIntoView({block: "start", inline: "nearest", behaviour: "instant"});
                  start.scrollIntoView({block: "start", inline: "nearest", behaviour: "instant"});
                  this.highlightSelectionNodes(document.getElementById(highlighting.start.id), document.getElementById(highlighting.end.id), "selection");
                } else if (this.focusedNodes.length === 1) {
                  this.focusedNodes[0].scrollIntoView({block: "start", inline: "nearest", behaviour: "instant"});
                  this.putCaretInNode(this.focusedNodes[0]);
                }
              }
            }
          }
        }, {once: true});
      });
      this.isEditMode = true;
    },
    async highlightSelectionNodes(start, end) {
      const p = start.parentNode;
      const pNodes = [...p.childNodes];
      const range = document.createRange();
      range.setStart(p, pNodes.indexOf(start));
      range.setEnd(p, pNodes.indexOf(end));
      const sel = window.getSelection();
      sel.empty();
      sel.addRange(range);
    },
    async putCaretInNode(node) {
      const range = document.createRange();
      range.setStart(node.childNodes[0], 0);
      const sel = window.getSelection();
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      node.focus();
      let anim = 5;
      while (anim > 0) {
        node.style.fontWeight = "1000";
        await new Promise((r) => setTimeout(r, 400));
        node.style.fontWeight = null;
        await new Promise((r) => setTimeout(r, 400));
        anim--;
      }
      node.style.transition = null;
    },
    checkAllPropertiesTrue(obj) {
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const nestedObj = obj[key];
          for (const prop in nestedObj) {
            if (nestedObj.hasOwnProperty(prop)) {
              if (nestedObj[prop] !== true) {
                return false; // Return false as soon as one property is not true
              }
            }
          }
        }
      }
      return true;
    },
    closeMenu() {
      document.removeEventListener("click", this.clickListener);
      this.menuTranscriptIndex = null;
      this.rightClickMenuVisible = false;
      this.menuX = 0;
      this.menuY = 0;
      this.highlightedRange = null;
      this.isSpanRightClicked = false;
      window.getSelection()?.empty();
    },
    prepareContentReplaceChanges(groups) {
      if (groups.length <= 0) throw new Error("No elements found");
      const changes = [];
      for (const els of groups) {
        const oldContent = els.reduce((all, el) => {
          all += el.dataset.o;
          return all;
        }, "");
        const startIndex = els[0].dataset.s;
        const endIndex = els[els.length - 1].dataset.e;
        const newContent = "";
        changes.push({startIndex, endIndex, newContent, oldContent});
      }
      return changes;
    },
    formatChange(el) {
      const oldContent = el.dataset.o;
      const startIndex = el.dataset.s;
      const endIndex = el.dataset.e;
      const oldContentTrimmed = oldContent.trim();
      const changedTo = el.innerText.trim();
      const originalIndex = oldContent.indexOf(oldContentTrimmed);
      const newContent = oldContent.slice(0, originalIndex) + changedTo + oldContent
        .slice(originalIndex + oldContent.length);
      return {startIndex, endIndex, newContent, oldContent};
    },
    async contentChange(formattedChanges) {
      return postChangeRequests(this.transcriptionId, formattedChanges);
    },
    removeSpeaker(crDetail) {
      if (this.disabled) return;
      postSpeakerChangeRequest(
        this.transcriptionId, crDetail.speakerPos, null, null, true, false, null, null, null
      ).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Successfully requested to update Speaker");
      }).catch((ex) => {
        this.$notifyError("Unable to request new Speaker", ex);
      });
    },
    selectSpeaker(crDetail) {
      if (this.disabled) return;
      const me = this;
      this.$emit("selectSpeaker", {
        uid: crDetail.speakerUserId,
        aid: crDetail.speakerActorId,
        startIndex: crDetail.speakerPos,
        callback: (opts, p, shouldMapMatching) => {
          if (!isDefined(p)) return;
          const userId = p.userId;
          const actorId = p.actorId;
          if (!isDefined(actorId) && !isDefined(userId)) return;
          postSpeakerChangeRequest(
            this.transcriptionId,
            opts.startIndex,
            null,
            null,
            false,
            false,
            shouldMapMatching,
            userId,
            actorId
          )
            .then((t) => {
              this.$emit("updated", t);
              me.$notifySuccess("Successfully requested to update Speaker");
            }).catch((ex) => {
              me.$notifyError("Unable to request new Speaker", ex);
            });
        },
      });
    },
    markClear() {
      if (this.disabled) return;
      const span = this.focusedNodes[0];
      if (!span) return;
      const start = span.dataset.s;
      const end = span.dataset.e;
      postMarkClear(this.transcriptionId, start, end, span.innerText).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    unRedactContent() {
      if (this.disabled) return;
      let start = null;
      let end = null;
      let content = null;
      if (!this.highlightedRange) {
        const span = this.focusedNodes[0];
        if (!span) return;
        start = span.dataset.s;
        end = span.dataset.e;
        content = span.innerText;
      } else {
        start = this.highlightedRange.start;
        end = this.highlightedRange.end;
        content = this.highlightedRange.text;
      }
      postRemoveRedaction(this.transcriptionId, start, end, content).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
        this.highlightedRange = null;
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    redactContent() {
      if (this.disabled) return;
      let start = null;
      let end = null;
      let content = null;
      if (!this.highlightedRange) {
        const span = this.focusedNodes[0];
        if (!span) return;
        start = span.dataset.s;
        end = span.dataset.e;
        content = span.innerText;
      } else {
        start = this.highlightedRange.start;
        end = this.highlightedRange.end;
        content = this.highlightedRange.text;
      }
      postRedaction(
        this.transcriptionId,
        start,
        end,
        content
      ).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
        this.highlightedRange = null;
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    createIncident() {
      if (this.disabled) return;
      this.$refs.incidentForm.display(this.highlightedRange);
      this.highlightedRange = null;
    },

    assignToSpeaker() {
      if (this.disabled) return;
      if (this.highlightedRange == null && this.rightClickTarget != null) {
        let startNode = null;
        let endNode = null;
        // highlights the speaker content based on where the right click was,
        // if a speaker tag was right clicked, it will emulate a click on it
        if (this.rightClickTarget.className.indexOf("span-container") > -1) {
          // span container was right clicked
          startNode = this.rightClickTarget.firstChild;
          endNode = this.rightClickTarget.lastChild;
        } else if (this.rightClickTarget.dataset.spk != null) {
          // speaker node was right clicked, emulate click on speaker
          const s = this.$refs[`spk-${this.rightClickTarget.dataset.spk}`];
          s[0].select();
          return;
        } else if (this.rightClickTarget instanceof HTMLSpanElement) {
          // span was right clicked
          startNode = this.rightClickTarget.parentNode.firstChild;
          endNode = this.rightClickTarget.parentNode.lastChild;
        } else if (this.rightClickTarget instanceof HTMLDivElement) {
          // div was right clicked
          const spanContainer = this.rightClickTarget.nextSibling;
          startNode = spanContainer.firstChild;
          endNode = spanContainer.lastChild;
        } else {
          // something else was right clicked
          return;
        }
        this.highlightedRange = {
          start: startNode.dataset.s,
          end: endNode.dataset.e,
          startTimeOffset: startNode.dataset.so,
          endTimeOffset: startNode.dataset.eo,
          text: this.content.slice(startNode.dataset.s, endNode.dataset.e),
          type: IncidentType.transcript,
          transcriptId: this.transcriptionId,
          uploadId: this.uploadId,
          nodes: {
            start: startNode,
            end: endNode,
          },
        };
        this.highlightOnContentRange(startNode, endNode, "selected");
      }

      if (this.highlightedRange != null) {
        const startNode = this.highlightedRange.nodes.start;
        const endNode = this.highlightedRange.nodes.end;
        this.highlightOnContentRange(startNode, endNode, "selected");
        this.assignSpeakerEventData = Object.assign({}, this.highlightedRange);
        this.$refs.assignToSpeakerForm.showModal = true;
      }
    },
    closeAssignSpeakerForm() {
      this.closeMenu();
    },
    onClipRange(clipRange) {
      if (clipRange) {
        console.log("clipRange in", clipRange.in, "out", clipRange.out);
      }
      this.clipRange = clipRange;
    },
    viewIncident() {
      const spanS = this.focusedNodes[0].dataset.s;
      const spanE = this.focusedNodes[0].dataset.e;
      try {
        const incident = this.$refs[`trSpan-${spanS}-${spanE}`][0]?.incident;
        this.viewClip(incident);
      } catch (ex) {
        console.warn(ex);
      }
    },
    viewClip(incident) {
      if (isDefined(incident) && isDefined(incident.clipId)) {
        this.$router.push({name: ethosRouteNames.ClipV2, params: {clipId: incident.clipId}});
      }
    },
    editIncident(incident) {
      if (this.disabled) return;
      if (!this.displayIncidents) {
        this.viewClip(incident);
        return;
      }
      this.$refs.incidentForm.display(
        {
          start: incident.startOffset,
          end: incident.endOffset,
          text: this.content.slice(incident.startOffset, incident.endOffset),
          type: IncidentType.transcript,
          transcriptId: this.transcriptionId,
          uploadId: this.uploadId,
        },
        incident
      );
      this.highlightedRange = null;
    },
    approve(data) {
      if (this.disabled) return;
      postChangeRequestApproval(this.transcriptionId, data.cr.id, true, data.reason).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Approved!");
      }).catch((ex) => {
        this.$notifyError("Unable to Approve the change", ex);
      });
    },
    reject(data) {
      if (this.disabled) return;
      postChangeRequestApproval(this.transcriptionId, data.cr.id, false, data.reason).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Change Rejected Successfully!");
      }).catch((ex) => {
        this.$notifyError("Unable to Reject the change", ex);
      });
    },
    undoChangeRequest(data) {
      if (this.disabled) return;
      postChangeRequests(
        this.transcriptionId,
        [{startIndex: data.s, endIndex: data.e, newContent: data.f, oldContent: data.t}]
      ).then((t) => {
        this.$emit("updated", t);
        this.$notifySuccess("Undo Change Requested Successfully!");
      }).catch((ex) => {
        this.$notifyError("Unable to Request to undo the change, please refresh.", ex);
      });
    },
    goToNextSpeaker() {
      this.adjacentSpeaker(true);
    },
    goToPrevSpeaker() {
      this.adjacentSpeaker(false);
    },
    adjacentSpeaker(forwards) {
      if (this.disabled) return;
      const editing = this.editingSpeakerAt;
      this.doSaveEdits(editing).then(() => {
        const lastSpeaker = document.querySelector(`div[data-i="${editing}"]`);
        let sibling = forwards ? lastSpeaker.nextSibling : lastSpeaker.previousSibling;
        while (sibling !== null && sibling.className && sibling.className.indexOf("span-container") === -1) {
          sibling = forwards ? sibling.nextSibling : sibling.previousSibling;
        }
        if (sibling != null) {
          try {
            this.focusedSection = parseInt(sibling.dataset["i"], 10);
            this.menuItemEdit(forwards ? "forwards" : "reverse");
          } catch (ex) {
            console.log("Unable to find section index");
          }
        }
      }).catch((ex) => {
        this.cancelEdits();
        this.$notifyError("Cancelling edits, unable to save", ex);
      });
    },
    editMetadata(e) {
      if (this.disabled) return;
      // with v2 this path should now be only used for the clip form display,
      // the metadataForm has been moved higher level to the ViewUploads component
      // where the transcript tabs live. 11/23/23 jk
      const clip = this.clip;
      if (isDefined(clip)) {
        this.$refs.clipForm.display(0, 0, clip);
      }
    },
    createdIncident(incident) {
      this.$emit("incident", incident);
    },
    updatedIncident(incident) {
      this.$emit("incident", incident);
    },
    formatTime(float) {
      let allSeconds = Math.round(float);
      const seconds = allSeconds % 60;
      allSeconds -= seconds;
      const minutes = Math.floor(allSeconds / 60) % 60;
      allSeconds -= minutes * 60;
      const hours = Math.floor(allSeconds / (60 * 60)) % 60;
      return `+ ${`${hours}`.padStart(2, "0")}:${`${minutes}`.padStart(2, "0")}:${`${seconds}`.padStart(2, "0")}`;
    },
    copyEventListener(e) {
      e.preventDefault();
      this.copyHighlightedText();
    },
    async copyHighlightedText(closeMenu = false) {
      const range = this.selection;
      const docFragment = range.cloneContents();
      const text = convertTranscriptDomToText(docFragment);
      try {
        await navigator.clipboard.writeText(text.trim());
        this.$notifySuccess("Copied to clipboard.");
      } catch (error) {
        console.error(error.message);
      }
      if (closeMenu) this.closeMenu();
    },
  },
};
</script>

