<template>
  <case-container
    :pagination="pagination"
    @changed="handlePaginationChanged"
  >
    <template slot="filter">
      <case-back-div
        v-if="false"
        :to="backTo"
      />
      <route-breadcrumb />
      <case-clips-filter
        :case-id="caseId"
        :pagination="pagination"
        :filter="filter"
        :missed-term="missedTerm"
        :subjects="caseActors"
        @changed="handleFilterChanged"
        @created="loadCaseClips"
      >
        <div
          v-if="showBulkActions"
          slot="actions"
          class="mx-3"
          style="margin-top: 0.65rem"
        >
          <el-dropdown
            type="primary"
            class="dropdown-actions dropdown-actions-simple text-primary-300"
            popper-class="select-primary"
            @command="handleBulkCommand"
          >
            <div class="font-weight-bold">
              Actions
              <ChevronDownIcon />
            </div>
            <el-dropdown-menu
              slot="dropdown"
              type="primary"
              class="dmenu select-primary bg-filter-chip"
              dark
            >
              <el-dropdown-item command="export">
                Export
              </el-dropdown-item>
              <el-dropdown-item command="status">
                Update Status
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </case-clips-filter>
    </template>
    <template v-if="hasData">
      <div :key="hasData">
        <template
          v-for="cucs in caseUploadClipSummaries"
        >
          <case-upload-item
            :key="cucs.uploadId + '_' + cucs.clips.length + '_' + cucs.startTimestamp"
            :case-upload-summary="cucs"
            :disable-actions="showBulkActions"
            :selected="selectedUploads.filter((s) => s.uploadId === cucs.uploadId).length === 1"
            limited
            @selected="handleSelected"
          />
          <div
            :key="cucs.uploadId + 'd1'"
            class="pl-5 evidence-sub--list pt-4"
          >
            <div
              v-if="cucs.clips && cucs.clips.length > 0"
              class="evidence-divider"
            />
            <case-clip-item
              v-for="cuc in cucs.clips"
              :key="refBuilder(cucs, cuc)"
              :ref="refBuilder(cucs, cuc)"
              :case-upload-clip-summary="cuc"
              :case-upload-summary="cucs"
              :disable-actions="showBulkActions"
              :selected="selectedClips.filter((s) => s.startOffset === cuc.startOffset &&
                s.endOffset === cuc.endOffset).length === 1"
              @selected="handleSelected"
              @updated="loadCaseClips"
            >
              <el-dropdown
                slot="actions"
                type="primary"
                class="dropdown-actions dropdown-actions-simple"
                :class="{
                  'text-primary-300': !showBulkActions,
                  'text-muted': showBulkActions,
                  'cursor-blocked': showBulkActions
                }"
                popper-class="select-primary"
                @command="(c) => handleActionItem(c, cucs.uploadId, cuc)"
              >
                <div class="font-weight-bold">
                  Actions
                  <ChevronDownIcon />
                </div>
                <el-dropdown-menu
                  v-if="!showBulkActions"
                  slot="dropdown"
                  type="primary"
                  class="dmenu select-primary bg-filter-chip"
                  dark
                >
                  <el-dropdown-item command="export">
                    Export
                  </el-dropdown-item>
                  <el-dropdown-item command="status">
                    Update Clip
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </case-clip-item>
          </div>
        </template>
      </div>
    </template>
    <clip-form-modal
      ref="clipform"
      :upload-id="exportData ? exportData.uploadId : -1"
    />
    <transcript-export-modal
      ref="exportModal"
      @export="handleExport"
    />
    <clip-status-modal
      ref="clipStatusModal"
      :upload-ids="selectedUploads.map((s) => s.uploadId)"
      :clips="selectedClips"
      @updated="() => clearSelected(true)"
      @close="clearSelected"
    />
    <loading-modal
      ref="loadingModal"
      copy="Downloading"
      additional-copy="This may take a few moments"
    />

    <download-blob
      ref="dlblob"
      :blob="dlFileBlob"
      :file-name="dlFileName"
      :content-type="dlFileContentType"
      :visible="false"
    />
  </case-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import {ChevronDownIcon} from "vue-feather-icons";
import {
  getCaseUploadClipSummariesPaged,
  downloadClip,
  getClip,
  getUploadById,
} from "../../../api";
import {isDefined} from "../../../api/helpers";
import CaseBackDiv from "../../../components/DashboardV2/Case/CaseBackDiv.vue";
import CaseClipsFilter from "../../../components/DashboardV2/Case/CaseClipsFilter.vue";
import CaseClipItem from "../../../components/DashboardV2/Case/CaseClipItem.vue";
import CaseUploadItem from "../../../components/DashboardV2/Case/CaseUploadItem.vue";
import CaseContainer from "../../../components/DashboardV2/Case/CaseContainer.vue";
import TranscriptExportModal from "../../../components/DashboardV2/Case/TranscriptExportModal.vue";
import {EMPTY_SEARCH_WRAPPER} from "../../../util/consts";
import {filterToQuery} from "../../../util/util";
import ClipFormModal from "../Uploads/ClipFormModal.vue";
import {ethosRouteNames} from "../../../routes/routeNames";
import DownloadBlob from "../../Ethos/DownloadBlob.vue";
import {buildExportClipContent, convertTranscriptDomToText, downloadClipTranscript, downloadZip, exportZip} from "../../../util/exportUtil";
import {ExportType} from "../../../util/exportUtil";
import LoadingModal from "../../../components/LoadingModal.vue";
import ClipStatusModal from "../../../components/DashboardV2/Case/ClipStatusModal.vue";
export default {
  components: {
    CaseContainer,
    CaseBackDiv,
    CaseClipsFilter,
    CaseClipItem,
    CaseUploadItem,
    ChevronDownIcon,
    ClipFormModal,
    TranscriptExportModal,
    DownloadBlob,
    LoadingModal,
    ClipStatusModal,
  },
  data() {
    return {
      selectedUploads: [],
      selectedClips: [],
      exportData: null,
      dloading: false,
      dlFileBlob: null,
      dlFileName: null,
      dlFileContentType: null,
    };
  },
  watch: {
    dloading(to) {
      if (this.$refs.loadingModal) {
        this.$refs.loadingModal.showModal = to;
      }
    },
  },
  computed: {
    ...mapGetters("data", [
      "getCaseUploadClipSummaries",
      "getCaseActorAttributions",
      "getActorMap",
      "getUserMap",
    ]),
    caseId() {
      const id = this.$route.params.caseId;
      return isDefined(id) ? parseInt(id, 10) : null;
    },
    backTo() {
      return ethosRouteNames.DashboardV2;
    },
    showBulkActions() {
      return (this.selectedClips ? this.selectedClips : []).length > 1;
    },
    caseUploadClipSummariesPaged() {
      const emptySearchWrapper = Object.assign({}, EMPTY_SEARCH_WRAPPER);
      if (!isDefined(this.getCaseUploadClipSummaries) || !isDefined(this.caseId)) {
        return emptySearchWrapper;
      }

      const cses = this.getCaseUploadClipSummaries[this.caseId] || emptySearchWrapper;
      return cses;
    },
    caseUploadClipSummaries() {
      return this.caseUploadClipSummariesPaged.data;
    },
    hasData() {
      return Array.isArray(
        this.caseUploadClipSummaries
      ) && this.caseUploadClipSummaries.length > 0 ? new Date().toISOString() : false;
    },
    pagination() {
      return this.caseUploadClipSummariesPaged.pagination;
    },
    filter() {
      return this.caseUploadClipSummariesPaged.filter;
    },
    missedTerm() {
      return this.caseUploadClipSummariesPaged.missedTerm;
    },
    caseActors() {
      if (!isDefined(this.caseId) || !isDefined(this.getCaseActorAttributions)) {
        return [];
      }
      return this.getCaseActorAttributions[this.caseId] ?? [];
    },
  },
  mounted() {
    this.loadCaseClips();
    document.addEventListener("copy", this.copyEventListener);
    if (isDefined(this.caseId) && this.caseActors.length === 0) {
      this.loadCasePeopleAttributions({caseId: this.caseId}).catch((ex) => {
        // Silent
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener("copy", this.copyEventListener);
  },
  methods: {
    ...mapActions("data", [
      "loadPeople",
      "loadCasePeopleAttributions",
    ]),
    ...mapMutations("data", ["putCaseUploadClipSummaries"]),
    loadCaseClips() {
      if (!isDefined(this.caseId)) return;
      this.loading = true;
      getCaseUploadClipSummariesPaged(
        this.caseId,
        this.pagination.CurrentPage,
        this.pagination.PageSize,
        filterToQuery(this.filter)
      )
        .then((response) => {
          if (!isDefined(response) || !isDefined(response.data)) throw new Error("Unable to get CaseEvidencePaged");
          this.putCaseUploadClipSummaries({
            caseId: this.caseId,
            ...response,
          });
        })
        .catch((ex) => {
          this.$notifyError("Failed to gather Clips for Case", ex);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handlePaginationChanged(newPagination) {
      this.putCaseUploadClipSummaries({
        caseId: this.caseId,
        pagination: newPagination,
      });
      this.loadCaseClips();
    },
    handleFilterChanged(newFilter) {
      this.putCaseUploadClipSummaries({
        caseId: this.caseId,
        filter: newFilter,
      });
      this.loadCaseClips();
    },
    handleSelected(data) {
      if (!data || !data.data) return;
      if (isDefined(data.data.startOffset)) {
        if (data.selected) {
          if (this.selectedClips.findIndex((s) => s.startOffset === data.data.startOffset &&
            s.endOffset === data.data.endOffset) === -1
          ) {
            this.selectedClips.push(data.data);
          }
        } else {
          this.selectedClips = this.selectedClips.filter((s) => !(s.startOffset === data.data.startOffset &&
            s.endOffset === data.data.endOffset)
          );
        }
      } else if (isDefined(data.data.uploadId)) {
        data.data.clips.forEach((c) => {
          const ref = this.refBuilder(data.data, c);
          if (this.$refs[ref] && this.$refs[ref][0]) this.$refs[ref][0].checkbox = data.selected;
        });
        if (data.selected) {
          if (this.selectedUploads.findIndex((s) => s.uploadId === data.data.uploadId) === -1) {
            this.selectedUploads.push(data.data);
          }
        } else {
          this.selectedUploads = this.selectedUploads.filter((s) => s.uploadId !== data.data.uploadId);
        }
      }
    },
    handleBulkCommand(command) {
      if (!this.showBulkActions) return;
      switch (command) {
        case "export":
          this.$refs.exportModal.showModal = true;
          break;
        case "status":
          this.$refs.clipStatusModal.display();
          break;
        default: break;
      }
    },
    handleActionItem(action, uploadId, clip) {
      this.exportData = {
        uploadId,
        clipId: clip.id,
        startOffset: clip.startOffset,
        endOffset: clip.endOffset,
      };
      this.$nextTick(() => {
        switch (action) {
          case "export":
            this.$refs.exportModal.showModal = true;
            break;
          case "status":
            this.$refs.clipform.display(clip.startOffset, clip.endOffset, clip);
            break;
          default: break;
        }
      });
    },
    copyEventListener(e) {
      e.preventDefault();
      const selection = window.getSelection();
      if (!selection.rangeCount) return;

      const range = selection.getRangeAt(0);
      const docFragment = range.cloneContents();
      const text = convertTranscriptDomToText(docFragment);
      e.clipboardData.setData("text/plain", text.trim());
    },
    refBuilder(cucs, cuc) {
      return cucs.uploadId + "__" + cuc.id + "_" + cuc.startOffset + "_" + cuc.status;
    },
    handleExport(data) {
      if (!isDefined(this.exportData)) {
        if (this.showBulkActions) {
          return this.handleBulkExport(data, this.selectedUploads.map((s) => s.uploadId), this.selectedClips.slice());
        }
        return;
      }
      this.loadPeople().then(() => {
        if (data.type === ExportType.audio) {
          this.downloadAudio(this.exportData.uploadId, this.exportData.clipId);
        } else {
          this.downloadTranscript(this.exportData.uploadId, this.exportData.clipId, data.type, data.withAudio);
        }
      });
    },
    handleBulkExport(data, _, clips) {
      if (this.dloading) return;
      this.dloading = true;
      const groupByIds = [];
      clips.forEach((c) => {
        if (groupByIds.indexOf(c.uploadId) === -1) groupByIds.push(c.uploadId);
      });
      this.loadPeople()
        .then(() => Promise.all(groupByIds.map((id) => getUploadById(id, true))))
        .then((uploads) => Promise.all(clips.map((c) => {
          const upload = uploads.find((u) => u.id === c.uploadId);
          return getClip(c.uploadId, c.id).then((clip) => {
            return {clip, upload};
          });
        })))
        .then((contents) => {
          if (data.type === ExportType.audio || data.withAudio) {
            return Promise.all(contents
              .map((c) => downloadClip(c.clip.uploadId, c.clip.id)
                .then((audio) => {
                  return {upload: c.upload, clip: c.clip, audio};
                })
              )
            );
          }
          return contents;
        })
        .then((contents) => contents.map((c) => buildExportClipContent(
          c, data.type, this.getUserMap, this.getActorMap
        )))
        .then(exportZip)
        .then((zipBlob) => {
          if (zipBlob) {
            downloadZip(zipBlob);
          }
        })
        .catch((ex) => {
          this.$notifyError("Unable to export files", ex);
        })
        .finally(() => {
          this.dloading = false;
        });
    },
    downloadTranscript(uploadId, clipId, type, withAudio) {
      if (this.dloading) return;
      this.dloading = true;
      downloadClipTranscript(
        this.loadPeople, getClip, downloadClip,
        this.getUserMap, this.getActorMap,
        uploadId, clipId, type, withAudio
      )
        .catch((ex) => {
          this.$notifyError("Unable to download file", ex);
        })
        .finally(() => {
          this.dloading = false;
          this.exportData = null;
        });
    },
    downloadAudio(uploadId, clipId) {
      if (this.dloading) return;
      this.dloading = true;
      downloadClip(uploadId, clipId)
        .then((blob) => {
          this.dlFileBlob = blob;
          this.dlFileName = `${this.exportData.startOffset}:${this.exportData.endOffset}.wav`;
          this.dlFileContentType = "audio/wav";
          this.$nextTick(() => {
            this.$notifySuccess("Downloading to file");
            this.$refs.dlblob.directDownload();
          });
        }).catch((ex) => {
          this.$notifyError("Unable to download file", ex);
        }).finally(() => {
          this.dloading = false;
          this.exportData = null;
        });
    },
    clearSelected(shouldReload) {
      this.selected = [];
      if (shouldReload === true) {
        this.loadCaseClips();
      }
    },
  },
};
</script>

<style></style>
